import React, { useEffect, useState } from 'react'
import MenuFunzionalita from "./MenuFunzionalita";
import { Container, Alert } from "react-bootstrap";
import FiltriPerSettimana from "./FiltriPerSettimana";
import TabellaGiorno from "./TabellaGiorno";
import { buildUrl } from '../helper/handler';
import { getFromApi } from '../utilities/NetUtilities';
import moment from "moment";


function OrarioLavoroSettimana(props) {

    const [message, setMessage] = useState([])
    const [turni, setTurni] = useState(null)
    const [settimana, setSettimana] = useState(null)
    const [sedi, setSedi] = useState();
    const [lavoratori, setLavoratori] = useState(null)
    const [servizi, setServizi] = useState(null)

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        const setMessageFromApi = (data) => {
            setMessage(message => [...message, { tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione }])
        }
        const setTurniFromApi = (data) => {
            setTurni(data.turni);
        }
        const setSediFromApi = (data) => {
            setSedi(data.sedi);
        }
        const setLavoratoriFromApi = (data) => {
            setLavoratori(data.lavoratori.map(lavoratore => ({
                "value": lavoratore.id,
                "label": lavoratore.nomeCognome
            })));
        }
        const setServiziFromApi = (data) => {
            setServizi(data.servizi);
        }
        if(settimana === null) {
            getFromApi(buildUrl('settimanaCorrenteTurniLavoro'), setSettimana, props.setMessage);
        }
        if (organizzazioneMap) {
            getFromApi(buildUrl('lavoratori', {organizzazione : organizzazioneMap.nome,tipo:organizzazioneMap.tipo}), setLavoratoriFromApi, setMessageFromApi);
            getFromApi(buildUrl('servizi', {organizzazione : organizzazioneMap.nome,tipo:organizzazioneMap.tipo}), setServiziFromApi, setMessageFromApi)
            const url = buildUrl('sedi', { organizzazione: organizzazioneMap.nome, tipo: organizzazioneMap.tipo });
            getFromApi(url, setSediFromApi, setMessageFromApi);     
            if (settimana) {
                const url = buildUrl('turniPerSettimana', { settimana: settimana.value, organizzazione: organizzazioneMap.nome, tipo: organizzazioneMap.tipo });
                getFromApi(url, setTurniFromApi, setMessageFromApi);
            }
        }
    }, [settimana]);

    function filtroChanged(newSettimana) {
        setSettimana(newSettimana)
    }

    return (
        sedi && servizi && lavoratori && <div className='main' role="main">
            <div>
                <MenuFunzionalita />
                <h1 className="d-flex justify-content-center inEvidenza" id="title_home">Turni di lavoro per settimana</h1>
                {
                    message && message.map((data, key) => (
                        <Alert variant={data.tipo} id={`message-${key}`} key={key}>
                            {data.descrizione}
                        </Alert>
                    ))}
                <Container className="">
                    <FiltriPerSettimana setMessage={setMessage} setSettimana={setSettimana} filtroChanged={filtroChanged} />
                </Container>
                <Container fluid>
                    {turni !== null && Object.keys(turni).map((data, index) => {
                        var turniGiornoSedePostazioneMattinaPomeriggio = turni[data];
                        return (
                        <div key={`tabella-${index}`}>
                            <div className="inEvidenza">{moment(data).format('dddd DD MMMM').toUpperCase()}</div>
                            <TabellaGiorno 
                                id={moment(data).format('dddd')}
                                turni={turniGiornoSedePostazioneMattinaPomeriggio}
                                setMessage = {setMessage}
                                data={data}
                                sedi={sedi}
                                lavoratori={lavoratori}
                                servizi={servizi}>
                            </TabellaGiorno>
                        </div>)

                    })}
                </Container>

            </div>
        </div>
    )
}

export default OrarioLavoroSettimana;