import React, { useEffect, useState } from 'react';
import 'reactjs-popup/dist/index.css';
import moment from 'moment';
import { TurniGiorno } from './TurniGiorno';
import { TotaleOre } from './TotaleOre';
import { buildUrl } from '../helper/handler';
import { getFromApi } from '../utilities/NetUtilities';

function TabellaOrarioPerLavoratore(props) {
    const [turni, setTurni] = useState(null);
    const [lavoratore, setLavoratore] = useState(null);
    const [settimana, setSettimana] = useState(null);
    const [turniGiorno, setTurniGiorno] = useState(null);

    const isLoaded = () => lavoratore === props.lavoratore.value && settimana === props.settimana;

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem('organizzazione'));
        const setTurniFromApi = (data) => {
            setTurni(data.turni);
            setLavoratore(props.lavoratore.value);
            setSettimana(props.settimana);
        };
        const getTurni = (lavoratore, settimana) => {
            if (lavoratore && settimana && organizzazioneMap) {
                const url = buildUrl('turniPerLavoratore', {
                    lavoratore: lavoratore,
                    settimana: settimana,
                    organizzazione: organizzazioneMap.nome,
                    tipo: organizzazioneMap.tipo,
                });
                getFromApi(url, setTurniFromApi, props.setMessage);
            }
        };
        if (organizzazioneMap) {
            getTurni(props.lavoratore.value, props.settimana);
        }
    }, [props.lavoratore.value, props.settimana, props.setMessage, turniGiorno]);

    const collectTurni = (turni) => {
        const allTurniMattina = [];
        const allTurniPomeriggio = [];
        Object.keys(turni).forEach((data) => {
            const turniGiorno = turni[data];
            if (turniGiorno.mattina) {allTurniMattina.push(...turniGiorno.mattina)};
            if (turniGiorno.pomeriggio) {allTurniPomeriggio.push(...turniGiorno.pomeriggio)};
        });
        return { allTurniMattina, allTurniPomeriggio };
    };

    const aggiungiTurnoRipetuto = (giorno, turno, mattina) => {
        var turniGiorno;
        if(mattina) {
            turniGiorno = turni[giorno].mattina;
        } else {
            turniGiorno = turni[giorno].pomeriggio;
        }
        for (var i = 0; i < turniGiorno.length; i++) {
            if (turniGiorno[i].id === turno.id) {
                turniGiorno.splice(i, 1);
            }
        }
        turniGiorno.push(turno);
        turniGiorno.sort(function (a, b) {
            return a.inizio > b.inizio
        })
        setTurniGiorno(turniGiorno)
    }

    const printTurni = () => {
        return Object.keys(turni).map((data) => {
            const turniGiorno = turni[data];
            return (
                <tr key={data}>
                    <td className="inEvidenza bordiSpessi">{moment(data).format('dddd DD MMMM')}</td>
                    <td className="bordiSpessi">
                        <TurniGiorno
                            setMessage={props.setMessage}
                            sedi={props.sedi}
                            servizi={props.servizi}
                            lavoratore={props.lavoratore}
                            giorno={data}
                            mattina={true}
                            turni={turniGiorno.mattina}
                            aggiungiTurnoRipetuto={aggiungiTurnoRipetuto}
                            lavoratori={props.lavoratori}
                            lavoratoreDisable={true}
                            postazioneDisable={false}
                            sedeDisable={false}
                            index={moment(data).format('dddd') + 'mattina'}
                            settimana = {props.settimana}
                        />
                    </td>
                    <td className="orario bordiSpessi">
                        <TotaleOre
                            turni={turniGiorno.mattina}
                            index={moment(data).format('dddd') + 'mattinatotore'}
                        />
                    </td>
                    <td className="bordiSpessi">
                        <TurniGiorno
                            setMessage={props.setMessage}
                            sedi={props.sedi}
                            servizi={props.servizi}
                            lavoratore={props.lavoratore}
                            giorno={data}
                            mattina={false}
                            turni={turniGiorno.pomeriggio}
                            aggiungiTurnoRipetuto={aggiungiTurnoRipetuto}
                            lavoratori={props.lavoratori}
                            lavoratoreDisable={true}
                            index={moment(data).format('dddd') + 'pomeriggio'}
                            settimana = {props.settimana}
                        />
                    </td>
                    <td className="orario bordiSpessi">
                        <TotaleOre
                            turni={turniGiorno.pomeriggio}
                            index={moment(data).format('dddd') + 'pomeriggiototore'}
                        />
                    </td>
                    <td className="orario bordiSpessi">
                        <TotaleOre
                            turni={[...(turniGiorno.mattina || []), ...(turniGiorno.pomeriggio || [])]}
                            index={moment(data).format('dddd') + 'giornalierototore'}
                        />
                    </td>
                </tr>
            );
        });
    };

    const { allTurniMattina, allTurniPomeriggio } = turni ? collectTurni(turni) : { allTurniMattina: [], allTurniPomeriggio: [] };

    return (
        <table className="table table-bordered space bordiSpessi">
            <thead className="App tableTurniLavoro">
                <tr>
                    <th className="inEvidenza bordiSpessi">giorno</th>
                    <th className="inEvidenza bordiSpessi">mattina</th>
                    <th className="inEvidenza bordiSpessi">totale (hh:mm)</th>
                    <th className="inEvidenza bordiSpessi">pomeriggio</th>
                    <th className="inEvidenza bordiSpessi">totale (hh:mm)</th>
                    <th className="inEvidenza bordiSpessi">totale giornaliero (hh:mm)</th>
                </tr>
            </thead>
            <tbody>
                {isLoaded() && turni && printTurni()}
                <tr>
                    <td className="inEvidenza bordiSpessi">Totale settimanale</td>
                    <td className="bordiSpessi"></td>
                    <td className="orario bordiSpessi">
                        <TotaleOre
                            turni={allTurniMattina}
                            index="settimanaleMattina"
                        />
                    </td>
                    <td className="bordiSpessi"></td>
                    <td className="orario bordiSpessi">
                        <TotaleOre
                            turni={allTurniPomeriggio}
                            index="settimanalePomeriggio"
                        />
                    </td>
                    <td className="orario bordiSpessi">
                        <TotaleOre
                            turni={[...allTurniMattina, ...allTurniPomeriggio]}
                            index="settimanaleGiornaliero"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default TabellaOrarioPerLavoratore;
