import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';
import { useRef, useEffect, useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { buildUrl } from '../helper/handler';
import { postToApi } from '../utilities/NetUtilities';
import Select from 'react-select';
import { Settimane } from '../helper/settimaneTurniLavoro';

function RipetiSettimana(props) {

    const [messaggiPopUp, setMessaggiPopUp] = useState([]);
    const [settimaneSelezionate, setSettimaneSelezionate] = useState([]);
    const [nomeOrganizzazione, setNomeOrganizzazione] = useState("");
    const [tipoOrganizzazione, setTipoOrganizzazione] = useState("");

    const ref = useRef();
    const selectRef = useRef();
    const closeTooltip = () => ref.current.close();

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        if (organizzazioneMap) {
            setNomeOrganizzazione(organizzazioneMap.nome);
            setTipoOrganizzazione(organizzazioneMap.tipo);
        }
    }, []);

    const settimanaChanged = (actual) => {
        setSettimaneSelezionate(actual);
    }

    const okAction = (data) => {
        props.setMessage([])
        data.messaggi.map((value, key) => (
            props.setMessage(message => [...message, {
                descrizione: value.messaggio,
                tipo: value.tipo.toLowerCase()
            }])
        ))
        setSettimaneSelezionate([])
        setMessaggiPopUp([])
        closeTooltip();
    }

    const setMessageFromApi = (data) => {
        setMessaggiPopUp([])
        data.messaggi.map((value, key) => (
            setMessaggiPopUp(message => [...message, {
                descrizione: value.descrizione,
                tipo: value.tipo.toLowerCase()
            }])
        ))
    }

    const ripetiSettimana = () => {
        if (settimaneSelezionate.length > 0) {
            const params = {
                settimanariferimento: props.settimanariferimento,
                settimane: settimaneSelezionate.map(settimana => settimana.value),
                lavoratore: props.lavoratore.value,
                organizzazione: nomeOrganizzazione,
                tipo: tipoOrganizzazione
            };
            console.log("params", params)
            const url = buildUrl('ripetiSettimana');
            console.log("url", url)
            postToApi(url, params, okAction, setMessageFromApi);
        } else {
            setMessaggiPopUp([{ descrizione: "scegliere almeno una settimana per cui ripetere i turni", tipo: "danger" }]);
        }
    }

    const handleClose = () => {
        setMessaggiPopUp([]);
        setSettimaneSelezionate([]);
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.data.value === props.settimana ? '#e6f2ff' : provided.backgroundColor,
            fontWeight: state.data.value === props.settimana ? 'bold' : 'normal'
        }),
    };

    const handleMenuOpen = () => {
        setTimeout(() => {
            if (selectRef.current) {
                const menuEl = selectRef.current.menuListRef;
                if (menuEl) {
                    const index = Settimane.findIndex(s => s.value === props.settimana);
                    if (index !== -1) {
                        const optionEl = menuEl.querySelector(`div[id$="-option-${index}"]`);
                        if (optionEl) {
                            optionEl.scrollIntoView({ behavior: 'auto', block: 'center' });
                        }
                    }
                }
            }
        }, 0);
    };

    return (
        <div>
            <Popup
                className='dimensione'
                ref={ref}
                trigger={<button className='ripetiSettimana' id="ripetisettimanabutton">
                    Ripeti settimana</button>}
                modal
                onClose={handleClose}>{
                    close => (
                        <div className="formGroup popUp" id="ripetisettimana">
                            {messaggiPopUp && messaggiPopUp.map((data, key) => (
                                <Alert variant={data.tipo} id="message" key={key}>
                                    {data.descrizione}
                                </Alert>))}
                            <Form.Label className='inEvidenza isolato'>
                                Scegliere le settimane in cui ripetere i turni della settimana che inizia il {props.settimana}:</Form.Label>
                            <Select
                                id="settimane"
                                name="settimane"
                                instanceId="settimane"
                                options={Settimane}
                                isMulti
                                onChange={settimanaChanged}
                                placeholder="Seleziona..."
                                value={settimaneSelezionate}
                                closeMenuOnSelect={false}
                                styles={customStyles}
                                onMenuOpen={handleMenuOpen}
                                ref={selectRef}
                            />
                            <div className="buttonPopUp">
                                <Button className="btnGenerale" variant="flat" size="sm" id="ripeti"
                                    onClick={ripetiSettimana}>Salva</Button>
                                <Button className="btnGenerale" variant="flat" size="sm" id="annulla"
                                    onClick={() => {
                                        setMessaggiPopUp([]); close()
                                    }}>Annulla</Button>
                            </div>
                        </div>)
                }
            </Popup>

        </div>
    )
}

export default RipetiSettimana;
