import React, { useEffect, useState } from 'react';
import { daMinutiAOre } from './turniHelper';
import {Button} from "react-bootstrap";

function TabellaLavoratore(props) {

    const [tabellaLavoratore, setTabellaLavoratore] = useState(null)
    const [totMinutiLavoratore, setTotMinutiLavoratore] = useState({})

    useEffect(() => {
        setTabellaLavoratore(props.tabellaLavoratore)
        setTotMinutiLavoratore(props.totMinutiLavoratore);
    }, [props.tabellaLavoratore])

    const printRigheSedi = (tabellaSedi, lavoratore, servizio, indexLavoratore, indexServizio, nRighe, nRigheServizio, rowClass) => {
        if(Object.keys(tabellaSedi).length === 0) {
            return (
                <tr id={indexLavoratore - indexServizio} key={`row-${indexLavoratore}-${indexServizio}`}  className={rowClass}>
                    {indexServizio === 0 && <td rowSpan={nRighe+1} id={'lavoratore-' + indexLavoratore + '-' + indexServizio} className="cellaSpaziosa inEvidenza bordiSpessi">{lavoratore}</td>}
                    {<td className='cellaSpaziosa bordiSpessi' rowSpan={nRigheServizio} id={'servizio-' + indexLavoratore + '-' + indexServizio}>{servizio}</td>}
                    <td  className='bordiSpessi' ></td>
                    <td className="center cellaSpaziosa bordiSpessi">{daMinutiAOre(0)}</td>
                </tr>
)
        } else {
        return (
            Object.keys(tabellaSedi).map((sede, indexSede) => {
                const minuti = tabellaSedi[sede];
                return (
                    <tr id={indexLavoratore - indexServizio - indexSede} key={`row-${indexLavoratore}-${indexServizio}-${indexSede}`} className={rowClass}>
                        {indexServizio === 0 && indexSede === 0 && <td rowSpan={nRighe+1} id={'lavoratore-' + indexLavoratore + '-' + indexServizio} className="inEvidenza bordiSpessi">{lavoratore}</td>}
                        {indexSede === 0 && <td rowSpan={nRigheServizio} id={'servizio-' + indexLavoratore + '-' + indexServizio} className='bordiSpessi'>{servizio}</td>}
                        <td className='cellaSpaziosa bordiSpessi'>{sede}</td>
                        <td className="center cellaSpaziosa bordiSpessi" id={'ore-lavoratore-' + indexLavoratore + '-' + indexServizio + '-' + indexSede}>{daMinutiAOre(minuti)}</td>
                    </tr>

                )
            }
            ))}

    }
    const printRighe = (tabellaServizi, lavoratore, indexLavoratore, nRighe, rowClass) => {
        console.log("lavoratore nRighe", lavoratore, nRighe)
        return (
            Object.keys(tabellaServizi).map((servizio, indexServizio) => {
                var tabellaSedi = tabellaServizi[servizio]
                var nRigheServizio = 1;
                if (Object.keys(tabellaSedi).length !== 0) {
                    nRigheServizio = Object.keys(tabellaSedi).length;
                }
                return (
                        printRigheSedi(tabellaSedi, lavoratore, servizio, indexLavoratore, indexServizio, nRighe, nRigheServizio, rowClass)
                )
            }))

    }
    const countRighe = (tabellaServizi, nRighe) => {
        var nRighe = 0;
        (Object.keys(tabellaServizi).map((servizio, indexServizio) => {
            var tabellaSedi = tabellaServizi[servizio];
            if (Object.keys(tabellaSedi).length === 0) {
                nRighe = nRighe + 1;
            } else {
                nRighe = nRighe + Object.keys(tabellaSedi).length;
            }
        }))
        return nRighe;
    }


    const printTabella = () => {
        return (
            Object.keys(tabellaLavoratore).map((lavoratore, indexLavoratore) => {
                const rowClass = indexLavoratore % 2 === 0 ? 'lineaGrigia' : 'lineaBianca';
                var tabellaServizi = tabellaLavoratore[lavoratore];
                var nRighe = countRighe(tabellaServizi);
                var minutiTot = totMinutiLavoratore[lavoratore];
                return (
                    <React.Fragment key={`table-${indexLavoratore}`}>
                        {printRighe(tabellaServizi, lavoratore, indexLavoratore, nRighe, rowClass)}
                        <tr key={`total-${indexLavoratore}`}>
                            <td className={`bordiSpessi ${rowClass}`}></td>
                            <td className={`inEvidenza right cellaSpaziosa bordiSpessi ${rowClass}`}>totale ore</td>
                            <td className={`inEvidenza center cellaSpaziosa bordiSpessi ${rowClass}`} id={'totorelavoratore-' + indexLavoratore}>{daMinutiAOre(minutiTot)}</td>
                        </tr>
                    </React.Fragment>
                );
            })
        );
    }


    return (
        <div id="tabellalavoratore" className='table-responsive'>
        <h2 className='inEvidenza'>Totale lavoratore 
            <Button data-toggle="tooltip" title="Download CSV" className='btnGenerale' variant="flat" size="sm" id="csvlavoratore"
                href={props.urlCsv}>scarica</Button></h2>
            {tabellaLavoratore && <table className="table-bordered bottom-space" >
                <thead  className="App">
                    <tr key="intestazione">
                        <th className="inEvidenza cellaSpaziosa bordiSpessi">lavoratore</th>
                        <th className="inEvidenza cellaSpaziosa bordiSpessi">servizio</th>
                        <th className="inEvidenza cellaSpaziosa bordiSpessi">sede</th>
                        <th className="inEvidenza cellaSpaziosa bordiSpessi">totale (hh:mm)</th>
                    </tr>
                </thead>
                <tbody>
                    {printTabella()}
                </tbody>
            </table>}
        </div>
    )
}

export default TabellaLavoratore;