
const HTTP_STATUS = {
  OK: 200,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
};

function addAuthorizationHeader(headers) {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    const username = sessionStorage.getItem("username") || '';
    if (username) {
      const password = sessionStorage.getItem("password") || '';
      headers["Authorization"] = 'Basic ' + window.btoa(username + ":" + password);
    }
  } else {
    console.error("sessionStorage non disponibile.");
  }
}

function getHeaderJson() {
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
  };
  addAuthorizationHeader(headers);
  return headers;
}

function getHeaderMultipart() {
  const headers = {};
  addAuthorizationHeader(headers);
  return headers;
}

function getJsonMethod() {
  return {
    method: "GET",
    headers: getHeaderJson(),
  };
}

export function getFromApi(url, okAction, errorAction) {
  fetch(url, getJsonMethod())
    .then(response => {
      var data;
      switch (response.status) {
        case HTTP_STATUS.OK:
          data = response.json();
          break;
        case HTTP_STATUS.UNAUTHORIZED:
          data = { ok: false, messaggi: [{ tipo: "warning", descrizione: "accesso non autorizzato" }] };
          break;
        case HTTP_STATUS.NOT_FOUND:
          data = { ok: false, messaggi: [{ tipo: "warning", descrizione: "La risorsa richiesta non è stata trovata" }] };
          break;
        default:
          data = { ok: false, messaggi: [{ tipo: "warning", descrizione: `Codice errore ${response.status}` }] };
          break;
      }
      return data;
    })
    .then((data) => {
      if (data.ok) {
        if (okAction) okAction(data);
      } else {
        if (errorAction) errorAction(data);
      }
    })
    .catch(errore => {
      errorAction({
        ok: false,
        messaggi: [{ tipo: "danger", descrizione: "si è verificato un errore: contattare l'assistenza" }]
      })
    });
}


export function postToApi(url, request, okAction, errorAction) {
  const body = JSON.stringify(request);
  fetch(url, {
    method: "POST",
    headers: getHeaderJson(),
    body: body,
  })
    .then(response => response.json())
    .then(data => {
      if (data.ok) {
        if (okAction) okAction(data);
      } else {
        if (errorAction) errorAction(data);
      }
    })
    .catch(errore => {
      console.log("error url", url)
      errorAction(
        {
          ok: false,
          messaggi: [{ tipo: "danger", descrizione: "si è verificato un errore: contattare l'assistenza" }]
        })
    });
}

export function postMultipartToApi(url, request, okAction, errorAction) {
  fetch(url, {
    method: "POST",
    headers: getHeaderMultipart(),
    body: request,
  })
    .then(response => response.json())
    .then(data => {
      if (data.ok) {
        if (okAction) okAction(data);
      } else {
        if (errorAction) errorAction(data);
      }
    })
    .catch(errore => errorAction({
      ok: false,
      messaggi: [{ tipo: "danger", descrizione: "si è verificato un errore: contattare l'assistenza" }]
    }));
}
