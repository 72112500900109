import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap"
import Select from 'react-select';
import { buildUrl } from '../helper/handler';
import { getFromApi } from '../utilities/NetUtilities'
import { Settimane } from '../helper/settimaneTurniLavoro';

function FiltriPerLavoratore(props) {
  const [lavoratore, setLavoratore] = useState(null)
  const [settimana, setSettimana] = useState(null)

  const handleSettimana = (data) => {
    const settimanaCorrente = Settimane.find(s => s.value === data.value);
    setSettimana(settimanaCorrente);
  };

  useEffect(() => {
    const setMessageFromApi = (data) => {
      props.setMessage(message => [...message, {tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione}])
    }

    getFromApi(buildUrl('settimanaCorrenteTurniLavoro'), handleSettimana, setMessageFromApi);
  }, [props.setMessage]);

  const filtroChanged = (newLavoratore, newSettimana) => {
    if (newLavoratore && newSettimana) {
      props.filtroChanged(newLavoratore, newSettimana.value);
    }
  }

  const lavoratoreChanged = (newLavoratore) => {
    setLavoratore(newLavoratore);
    filtroChanged(newLavoratore, settimana);
  }

  const settimanaChanged = (newSettimana) => {
    setSettimana(newSettimana);
    filtroChanged(lavoratore, newSettimana);
  }

  return (
    <Row className='filtri'>
      <Col xl={3} sm={4} xs={12} id="lavoratorefiltro">
        <p className="inEvidenza">lavoratore</p>
        <Select
          name="lavoratore"
          instanceId="lavoratore"
          options={props.lavoratori}
          onChange={lavoratoreChanged}
          placeholder="Seleziona..."
          value={lavoratore}
        />
      </Col>
      <Col xl={6} sm={8} xs={12} id="settimanafiltro">
        <p className="inEvidenza">settimana</p>
        <Select
          id="settimanafiltroselect"
          name="settimanainizio"
          instanceId="settimana"
          options={Settimane}
          onChange={settimanaChanged}
          placeholder="Seleziona..."
          value={settimana}
        />
      </Col>
    </Row>
  )
}

export default FiltriPerLavoratore;