import React, { useEffect, useState } from 'react';
import { getFromApi, postToApi } from '../utilities/NetUtilities';
import { buildUrl } from '../helper/handler';
import { Container, Alert, Table, Modal, Button, Row, Col } from "react-bootstrap";
import ServizioForm from './ServizioForm';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { SpinnerComp } from '../helper/spinner';

function GestioneServizi() {

    const [servizi, setServizi] = useState([]);
    const [message, setMessage] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentServizio, setCurrentServizio] = useState(null);
    const [organizzazione, setOrganizzazione] = useState({});
    const [servizioToDelete, setServizioToDelete] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        const setServiziFromApi = (data) => {
            console.log(data);
            const serviziArray = Object.keys(data.servizi).map(key => ({
                id: key,
                nome: data.servizi[key][0],
                colore: data.servizi[key][1]
            }));
            setServizi(sortServizi(serviziArray));
            setLoading(false);
        };

        if (organizzazioneMap) {
            getFromApi(buildUrl('servizi', { organizzazione: organizzazioneMap.nome, tipo: organizzazioneMap.tipo }), setServiziFromApi, setMessageFromApi);
            setOrganizzazione(organizzazioneMap);
        }
    }, []);

    const setMessageFromApi = (data) => {
        setMessage([]);
        data.messaggi.forEach((value) => {
            setMessage(prevMessage => [...prevMessage, {
                descrizione: value.descrizione,
                tipo: value.tipo.toLowerCase()
            }]);
        });
        setShowModal(false);
    };

    const handleShowModal = (servizio) => {
        setMessage([]);
        setCurrentServizio(servizio);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setCurrentServizio(null);
        setShowModal(false);
    };

    const handleShowConfirmModal = (servizio) => {
        setMessage([]);
        const servizioUpdated = {
            ...servizio,
            organizzazione:organizzazione.nome
        };
        setServizioToDelete(servizioUpdated);
        setShowConfirmModal(true);
    };

    const handleCloseConfirmModal = () => {
        setServizioToDelete({});
        setShowConfirmModal(false);
    };

    const action = (data) => {
        setMessage([]);
        data.messaggi.forEach((value) => {
            setMessage(prevMessage => [...prevMessage, {
                descrizione: value.descrizione,
                tipo: value.tipo.toLowerCase()
            }]);
        });
        refreshServizi(data.servizio);
        setShowModal(false);
    };

    const refreshServizi = (servizio) => {
        if (servizio) {
            setServizi(prevServizi => {
                const existingServizioIndex = prevServizi.findIndex(s => s.id === servizio.id);
                let updatedServizi;
                if (existingServizioIndex !== -1) {
                    updatedServizi = [...prevServizi];
                    updatedServizi[existingServizioIndex] = servizio;
                } else {
                    updatedServizi = [...prevServizi, servizio];
                }
                return sortServizi(updatedServizi);
            });
        }
    };

    const handleSaveServizio = (servizio) => {
        const servizioUpdated = {
            ...servizio,
            organizzazione:organizzazione.nome
        };
        if (!currentServizio) {
            servizioUpdated.organizzazione = organizzazione.nome;
        }
        const url = buildUrl(currentServizio ? 'editServizio' : 'addServizio');
        postToApi(url, servizioUpdated, action, setMessageFromApi);
    };

    const okActionDelete = (data) => {
        setServizi(prevServizi => prevServizi.filter(s => s.id !== servizioToDelete.id));
        setMessageFromApi(data);
    }

    const handleDeleteServizio = () => {
        const url = buildUrl('deleteServizio');
        postToApi(url, servizioToDelete, okActionDelete, setMessageFromApi);
        handleCloseConfirmModal();
    };

    const sortServizi = (servizi) => {
        return servizi.sort((a, b) => a.nome.localeCompare(b.nome));
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <SpinnerComp />
            </div>
        );
    }

    return (
        <div className='main' role="main">
            <div>
                <h1 className="d-flex justify-content-center inEvidenza" id="title_home">Gestione servizi</h1>
                {
                    message && message.map((data, key) => (
                        <Alert variant={data.tipo} id="message" key={key}>
                            {data.descrizione}
                        </Alert>
                    ))
                }
                <Container>
                    <FaPlus id="aggiungi_servizio" title='aggiungi servizio' className='actionIcon bottom-space-ridotto' onClick={() => handleShowModal(null)} />
                    {servizi.length > 0 ? (
                        <Table id="tabella_servizi" striped bordered hover>
                            <thead>
                                <tr>
                                    <th className="inEvidenza App">Nome</th>
                                    <th className="inEvidenza App">Colore</th>
                                    <th className="inEvidenza App">Azioni</th>
                                </tr>
                            </thead>
                            <tbody>
                                {servizi.map((servizio, index) => (
                                    <tr key={servizio.id}>
                                        <td>{servizio.nome}</td>
                                        <td>{servizio.colore}</td>
                                        <td>
                                            <div className="actionIconContainer">
                                                <FaEdit title='modifica servizio' id={`edit-servizio-${index}`} className='actionIcon' onClick={() => handleShowModal(servizio)} />
                                                <FaTrash title='elimina servizio' id={`delete-servizio-${index}`} className='actionIcon' onClick={() => handleShowConfirmModal(servizio)} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <Row className="justify-content-center">
                            <Col xs={12} className="text-center">
                                <p>Non ci sono servizi.</p>
                            </Col>
                        </Row>
                    )}
                    <FaPlus className='actionIcon' onClick={() => handleShowModal(null)} />
                </Container>
            </div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="title_popup">{currentServizio ? 'Modifica servizio' : 'Aggiungi servizio'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ServizioForm servizio={currentServizio} onSave={handleSaveServizio} onClose={handleCloseModal} />
                </Modal.Body>
            </Modal>

            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="title_popup">Conferma eliminazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>Sei sicuro di voler eliminare il servizio '{servizioToDelete.nome}' ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" id="annulla_elimina" onClick={handleCloseConfirmModal}>
                        Annulla
                    </Button>
                    <Button variant="danger" id="elimina" onClick={handleDeleteServizio}>
                        Elimina
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default GestioneServizi;
