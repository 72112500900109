import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { ChromePicker } from 'react-color';

function ServizioForm({ servizio, onSave, onClose }) {
    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [colore, setColore] = useState('#ffffff');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (servizio) {
            setId(servizio.id)
            setNome(servizio.nome);
            setColore(servizio.colore || '#ffffff');
        }
    }, [servizio]);

    const handleSave = () => {
        if (!nome.trim()) {
            setErrorMessage("Il nome è obbligatorio.");
            return;
        }
        const newServizio = {
            ...servizio,
            id: id,
            nome: nome,
            colore: colore
        };
        setErrorMessage('');
        onSave(newServizio);
    };

    return (
        <Form>
            {errorMessage && <Alert id="messaggio_popup" variant="danger">{errorMessage}</Alert>}
            <Row className="mb-3">
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control id="nome" type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>Colore</Form.Label>
                        <ChromePicker
                            color={colore}
                            onChangeComplete={(color) => setColore(color.hex)}
                            width="100%"
                            disableAlpha={true}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Button variant="primary" onClick={handleSave} id="salva">Salva</Button>
            <Button variant="secondary" onClick={onClose} id="annulla">Annulla</Button>
        </Form>
    );
}

export default ServizioForm;
