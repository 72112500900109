import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { buildUrl } from '../helper/handler';
import { getFromApi } from '../utilities/NetUtilities';
import { ChromePicker } from 'react-color';

function SedeForm({ sede, onSave, onClose }) {
    const [descrizione, setDescrizione] = useState('');
    const [postazioni, setPostazioni] = useState({});
    const [colore, setColore] = useState('');
    const [newPostazione, setNewPostazione] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (sede) {
            setDescrizione(sede.descrizione);
            setPostazioni(sede.postazioni);
            setColore(sede.colore);
        }
    }, [sede]);

    const handleSave = () => {
        if (!descrizione.trim()) {
            setErrorMessage("La descrizione è obbligatoria.");
            return;
        }
        if (Object.keys(postazioni).length === 0) {
            setErrorMessage("Va aggiunta almeno una postazione.");
            return;
        }
        const postazioniValues = Object.values(postazioni).map(value => value.trim().toLowerCase());
        const hasDuplicates = new Set(postazioniValues).size !== postazioniValues.length;
        if (hasDuplicates) {
            setErrorMessage("Non possono esserci postazioni con lo stesso nome.");
            return;
        }
        const newSede = {
            ...sede,
            descrizione: descrizione,
            postazioni: postazioni,
            colore: colore
        };
        setErrorMessage('');
        onSave(newSede);
    };

    const handleAddPostazione = () => {
        setErrorMessage('');
        if (!newPostazione.trim()) {
            setErrorMessage("Il nome della postazione è obbligatorio.");
        } else {
            const newKey = newPostazione.trim().replace(" ", "").toLowerCase();
            if (Object.values(postazioni).some(post => post.trim().toLowerCase() === newPostazione.trim().toLowerCase())) {
                setErrorMessage("Esiste già una postazione con questo nome");
                return;
            }
            setPostazioni({ ...postazioni, [newKey]: newPostazione.trim() });
            setNewPostazione('');
        }
    };

    const handleRemovePostazione = (key) => {
        setErrorMessage('');
        const removePostazione = () => {
            setPostazioni(prevPostazioni => {
                const updatedPostazioni = { ...prevPostazioni };
                delete updatedPostazioni[key];
                return updatedPostazioni;
            });
        };
        const okAction = () => {
            removePostazione();
        }
        const nokAction = (data) => {
            setErrorMessage(data.messaggi[0].descrizione);
        }
        if (!sede) {
            console.log("no sede")
            removePostazione();
        } else {
            const url = buildUrl('searchPostazione', { sedeId: sede.id, postazione: key });
            getFromApi(url, okAction, nokAction);
        }
    };

    const handleChangePostazione = (key, value) => {
        setPostazioni({ ...postazioni, [key]: value });
    };

    return (
        <Form>
            {errorMessage && <Alert id="messaggio_popup" variant="danger">{errorMessage}</Alert>}
            <Row className="mb-3">
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>Descrizione</Form.Label>
                        <Form.Control id="descrizione" type="text" value={descrizione} onChange={(e) => setDescrizione(e.target.value)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>Postazioni</Form.Label>
                        {Object.entries(postazioni).map(([key, value]) => (
                            <Row key={key} className="align-items-center mb-3">
                                <Col xs={10}>
                                    <Form.Control
                                        id={`postazione-${key}`}
                                        type="text"
                                        value={value}
                                        onChange={(e) => handleChangePostazione(key, e.target.value)}
                                    />
                                </Col>
                                <Col xs={2} className="d-flex justify-content-center">
                                    <FaTrash
                                        id={`rimuovi-postazione-${key}`}
                                        title='rimuovi postazione'
                                        className='actionIcon'
                                        onClick={() => handleRemovePostazione(key)}
                                    />
                                </Col>
                            </Row>
                        ))}
                        <Row className="align-items-center mb-3">
                            <Col xs={10}>
                                <Form.Control
                                    id="nuova-postazione"
                                    type="text"
                                    value={newPostazione}
                                    onChange={(e) => setNewPostazione(e.target.value)}
                                />
                            </Col>
                            <Col xs={2} className="d-flex justify-content-center">
                                <FaPlus
                                    id="aggiungi-postazione"
                                    title='aggiungi postazione'
                                    className='actionIcon'
                                    onClick={handleAddPostazione}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>Colore</Form.Label>
                        <ChromePicker
                            color={colore}
                            onChangeComplete={(color) => setColore(color.hex)}
                            width="100%"
                            disableAlpha={true}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Button variant="primary" onClick={handleSave} id="salva">Salva</Button>
            <Button variant="secondary" onClick={onClose} id="annulla">Annulla</Button>
        </Form>
    );
}

export default SedeForm;
