import React, { useEffect, useState } from 'react';
import { getFromApi, postToApi } from '../utilities/NetUtilities';
import { buildUrl } from '../helper/handler';
import { Container, Alert, Table, Modal, Button, Row, Col } from "react-bootstrap";
import SedeForm from './SedeForm';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { SpinnerComp } from '../helper/spinner';

function GestioneSedi() {

    const [sedi, setSedi] = useState([]);
    const [message, setMessage] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentSede, setCurrentSede] = useState(null);
    const [organizzazione, setOrganizzazione] = useState({});
    const [sedeToDelete, setSedeToDelete] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        const setSediFromApi = (data) => {
            setSedi(sortSedi(data.sedi));
            setLoading(false);
        };

        if (organizzazioneMap) {
            getFromApi(buildUrl('sedi', { organizzazione: organizzazioneMap.nome, tipo: organizzazioneMap.tipo }), setSediFromApi, setMessageFromApi);
            setOrganizzazione(organizzazioneMap);
        }
    }, []);

    const setMessageFromApi = (data) => {
        setMessage([]);
        data.messaggi.forEach((value) => {
            setMessage(prevMessage => [...prevMessage, {
                descrizione: value.descrizione,
                tipo: value.tipo.toLowerCase()
            }]);
        });
        setShowModal(false);
    };

    const handleShowModal = (sede) => {
        setMessage([]);
        setCurrentSede(sede);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setCurrentSede(null);
        setShowModal(false);
    };

    const handleShowConfirmModal = (sede) => {
        setMessage([]);
        setSedeToDelete(sede);
        setShowConfirmModal(true);
    };

    const handleCloseConfirmModal = () => {
        setSedeToDelete({});
        setShowConfirmModal(false);
    };

    const action = (data) => {
        setMessage([]);
        data.messaggi.forEach((value) => {
            setMessage(prevMessage => [...prevMessage, {
                descrizione: value.descrizione,
                tipo: value.tipo.toLowerCase()
            }]);
        });
        refreshSedi(data.sede);
        setShowModal(false);
    };

    const refreshSedi = (sede) => {
        if (sede) {
            setSedi(prevSedi => {
                const existingSedeIndex = prevSedi.findIndex(s => s.id === sede.id);
                let updatedSedi;
                if (existingSedeIndex !== -1) {
                    updatedSedi = [...prevSedi];
                    updatedSedi[existingSedeIndex] = sede;
                } else {
                    updatedSedi = [...prevSedi, sede];
                }
                return sortSedi(updatedSedi);
            });
        }
    };

    const handleSaveSede = (sede) => {
        const sedeUpdated = {
            ...sede
        };
        if (!currentSede) {
            sedeUpdated.organizzazione = organizzazione.nome;
        }
        const url = buildUrl(currentSede ? 'editSede' : 'addSede');
        postToApi(url, sedeUpdated, action, setMessageFromApi);
    };

    const okActionDelete = (data) => {
        setSedi(prevSedi => prevSedi.filter(s => s.id !== sedeToDelete.id));
        setMessageFromApi(data);
    }

    const handleDeleteSede = () => {
        const url = buildUrl('deleteSede');
        postToApi(url, sedeToDelete, okActionDelete, setMessageFromApi);
        handleCloseConfirmModal();
    };

    const sortSedi = (sedi) => {
        return sedi.sort((a, b) => a.descrizione.localeCompare(b.descrizione));
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <SpinnerComp />
            </div>
        );
    }

    return (
        <div className='main' role="main">
            <div>
                <h1 className="d-flex justify-content-center inEvidenza" id="title_home">Gestione Sedi</h1>
                {
                    message && message.map((data, key) => (
                        <Alert variant={data.tipo} id="message" key={key}>
                            {data.descrizione}
                        </Alert>
                    ))
                }
                <Container>
                    <FaPlus id="aggiungi_sede" title='aggiungi sede' className='actionIcon bottom-space-ridotto' onClick={() => handleShowModal(null)} />
                    {sedi.length > 0 ? (
                        <Table id="tabella_sedi" striped bordered hover>
                            <thead>
                                <tr>
                                    <th className="inEvidenza App">Descrizione</th>
                                    <th className="inEvidenza App">Postazioni</th>
                                    <th className="inEvidenza App">Colore</th>
                                    <th className="inEvidenza App">Azioni</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sedi.map((sede, index) => (
                                    <tr key={sede.id}>
                                        <td>{sede.descrizione}</td>
                                        <td>{Object.values(sede.postazioni).join(", ")}</td>
                                        <td id={`colore-sede-${index}`}>{sede.colore}</td>
                                        <td>
                                            <div className="actionIconContainer">
                                                <FaEdit title='modifica sede' id={`edit-sede-${index}`} className='actionIcon' onClick={() => handleShowModal(sede)} />
                                                <FaTrash title='elimina sede' id={`delete-sede-${index}`} className='actionIcon' onClick={() => handleShowConfirmModal(sede)} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <Row className="justify-content-center">
                            <Col xs={12} className="text-center">
                                <p>Non ci sono sedi.</p>
                            </Col>
                        </Row>
                    )}
                    <FaPlus className='actionIcon' onClick={() => handleShowModal(null)} />
                </Container>
            </div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="title_popup">{currentSede ? 'Modifica sede' : 'Aggiungi sede'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SedeForm sede={currentSede} onSave={handleSaveSede} onClose={handleCloseModal} />
                </Modal.Body>
            </Modal>

            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="title_popup">Conferma eliminazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>Sei sicuro di voler eliminare la sede '{sedeToDelete.descrizione}' ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" id="annulla_elimina" onClick={handleCloseConfirmModal}>
                        Annulla
                    </Button>
                    <Button variant="danger" id="elimina" onClick={handleDeleteSede}>
                        Elimina
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default GestioneSedi;
