import React, { useState, useEffect} from 'react';
import './css/App.css';
import { SpinnerComp } from './helper/spinner';
import { Button, Container, Row, Col } from 'react-bootstrap'
import Talk from './helper/Talk'
import { postMultipartToApi } from './utilities/NetUtilities';
import { buildUrl } from './helper/handler';

function CaricaFile() {

    const [message, setMessage] = useState([]);
    const [warning, setWarning] = useState([]);
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState("");
    const [isFilePicked, setIsFilePicked] = useState("");
    const [inputKey, setInputKey] = useState(Date.now());
    const [nomeOrganizzazione, setNomeOrganizzazione] = useState("")
    const [tipoOrganizzazione, setTipoOrganizzazione] = useState("")

    const fatture = "fatture";
    const numero730 = "numero730";
    const appuntamenti = "appuntamenti";
    const isee = "isee";
    const url_fatture = "uploadfatture";
    const url_numero730 = "uploadnumero730";
    const url_appuntamenti = "uploadappuntamenti";
    const url_isee = "uploadisee";

    useEffect(() => {
        const init = async () => {
            const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"))
            setNomeOrganizzazione(organizzazioneMap.nome)
            setTipoOrganizzazione(organizzazioneMap.tipo)
        }   
        init();
      }, [])

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(event.target.name);
        setMessage([]);
        setWarning([]);
        setError([]);
        console.log("set");
    };

    const okAction = (data) => {
        console.log(data)
        setLoading(false)
        console.log("data", data.messaggi)
        const errori = [];
        const warning = [];
        const messaggi = [];
        data.messaggi.map((actual) => {
            console.log("tipo", actual.tipo)
            if(actual.tipo === 'danger') {
                errori.push(actual.descrizione);
            }
            if(actual.tipo === 'warning') {
                warning.push(actual.descrizione);
            }
            if(actual.tipo === 'info') {
                messaggi.push(actual.descrizione);
            }
        })
        setMessage(messaggi);
        setWarning(warning);
        setError(errori);
    }

    const nokAction = (data) => {
        console.log(data)
        setLoading(false)
        setMessage([]);
        if(data.messaggi[0].tipo === "warning") {
            setWarning([data.messaggi[0].descrizione]);
        } else {
            setError([data.messaggi[0].descrizione]);
        }
    }

    const handleSubmission = (controller, tipo) => {
        const formData = new FormData();
        formData.append(tipo, selectedFile);
        formData.append("organizzazione", nomeOrganizzazione)
        formData.append("tipo", tipoOrganizzazione)
        const url = buildUrl(controller)
        console.log("url controller", url)
        if (isFilePicked === tipo) {
            setLoading(true);
            postMultipartToApi(url, formData, okAction, nokAction);
            setSelectedFile(false);
            setInputKey(Date.now);
            setIsFilePicked("");
        }
        else {
            const listMessage = ["scegliere un file"];
            setMessage(listMessage);
        }
    };

    if (loading === true) {
        return <SpinnerComp />
    }

    return (
        <div className='main2' role='main'>
            <Container fluid>
                <Talk messaggi={message} warning={warning} errori={error} />
                <h1 className="d-flex justify-content-center">Importazione dati 
                ({nomeOrganizzazione.toLowerCase()}/{tipoOrganizzazione.toLowerCase()})</h1>
                <div className="space divCaricaFile">
                    <Row>
                        <Col xs lg="3">
                            <p className="form-label labelfile ">File fatturato</p>
                        </Col>
                        <Col xs lg="7">
                            <input className="form-control form-control-lg" id="inputFileFattura" aria-label='File fatturato' type="file" name={fatture} onChange={changeHandler} key={inputKey} />
                        </Col>
                        <Col xs lg="2">
                            <Button className="btnGenerale" variant="flat" size="sm" id="carica_fattura" onClick={() => handleSubmission(url_fatture, fatture)}>Carica</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg="3">
                            <p className="form-label labelfile">File numero 730</p>
                        </Col>
                        <Col xs lg="7">
                            <input className="form-control form-control-lg" id="inputFile730" aria-label='File numero 730' type="file" name={numero730} onChange={changeHandler} key={inputKey} />
                        </Col>
                        <Col xs lg="2">
                            <Button className="btnGenerale" variant="flat" size="sm" id="carica_numero730" onClick={() => handleSubmission(url_numero730, numero730)}>Carica</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg="3">
                            <p className="form-label labelfile">File appuntamenti</p>
                        </Col>
                        <Col xs lg="7">
                            <input className="form-control form-control-lg" id="inputFileAppuntamenti" aria-label='File appuntamenti' type="file" name={appuntamenti} onChange={changeHandler} key={inputKey} />
                        </Col>
                        <Col xs lg="2">
                            <Button className="btnGenerale" variant="flat" size="sm" id="carica_appuntamenti" onClick={() => handleSubmission(url_appuntamenti, appuntamenti)}>Carica</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg="3">
                            <p className="form-label labelfile">File isee</p>
                        </Col>
                        <Col xs lg="7">
                            <input className="form-control form-control-lg" id="inputFileIsee" aria-label='File isee' type="file" name={isee} onChange={changeHandler} key={inputKey} />
                        </Col>
                        <Col xs lg="2">
                            <Button className="btnGenerale" variant="flat" size="sm" id="carica_isee" onClick={() => handleSubmission(url_isee, isee)}>Carica</Button>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default CaricaFile;