import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap"
import Select from 'react-select';
import { buildUrl } from '../helper/handler';
import { getFromApi } from '../utilities/NetUtilities'
import { Settimane } from '../helper/settimaneTurniLavoro';


function FiltriPerSettimana(props) {

    const [settimana, setSettimana] = useState(null)

    const handleSettimana = (data) => {
        const settimanaCorrente = Settimane.find(s => s.value === data.value);
        setSettimana(settimanaCorrente);
    };

    useEffect(() => {
        getFromApi(buildUrl('settimanaCorrenteTurniLavoro'), handleSettimana, props.setMessage);
        props.setSettimana(settimana)
    }, [props.setMessage]);

    const filtroChanged = (newSettimana) => {
        setSettimana(newSettimana);
        props.filtroChanged(newSettimana);
    }

    return (
        <Row className='filtri'>
            <Col xl={6} sm={8} xs={12} id="settimanafiltro">
                <p className="inEvidenza">settimana</p>
                <Select
                    name="settimanainizio"
                    instanceId="settimana"
                    options={Settimane}
                    onChange={filtroChanged}
                    placeholder="Seleziona..."
                    value={settimana}
                />
            </Col>
        </Row>
    )
}

export default FiltriPerSettimana;