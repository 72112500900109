
import React, { useEffect, useState } from 'react'
import MenuFunzionalita from "./MenuFunzionalita";
import FiltriPerLavoratore from "./FiltriPerLavoratore";
import TabellaOrarioPerLavoratore from "./TabellaOrarioPerLavoratore";
import RipetiSettimana from "./RipetiSettimana";
import { Container, Alert } from "react-bootstrap";

import { buildUrl } from '../helper/handler';
import { getFromApi } from '../utilities/NetUtilities';

function OrarioLavoroLavoratore() {

    const [sedi, setSedi] = useState();
    const [servizi, setServizi] = useState();
    const [message, setMessage] = useState([])
    const [filtro, setFiltro] = useState(null)
    const [lavoratori, setLavoratori] = useState([])

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        const setLavoratoriFromApi = (data) => {
            setLavoratori(data.lavoratori.map(lavoratore => ({
                "value": lavoratore.id,
                "label": lavoratore.nomeCognome
            })));
        }
        const setServiziFromApi = (data) => {
            setServizi(data.servizi);
        }
        const setSediFromApi = (data) => {
            setSedi(data.sedi);
        }
        const setMessageFromApi = (data) => {
            setMessage(message => [...message, {tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione}])
        }

        if(organizzazioneMap) {
            const urlLavoratori = buildUrl(
                'lavoratori',
                {
                    organizzazione : organizzazioneMap.nome,
                    tipo:organizzazioneMap.tipo
                });
            getFromApi(urlLavoratori, setLavoratoriFromApi, setMessageFromApi);
            getFromApi(buildUrl('servizi', {organizzazione : organizzazioneMap.nome,tipo:organizzazioneMap.tipo}), setServiziFromApi, setMessageFromApi)
            getFromApi(buildUrl('sedi', {organizzazione : organizzazioneMap.nome,tipo:organizzazioneMap.tipo}), setSediFromApi, setMessageFromApi);
        }
    }, [])

    function filtroChanged(newLavoratore, newSettimana) {
        setFiltro({
            lavoratore: newLavoratore,
            settimana: newSettimana
        })
        setMessage([])
    }

    return (
        <div className='main' role="main">
            <div>
                <MenuFunzionalita />
                <h1 className="d-flex justify-content-center inEvidenza" id="title_home">Turni di lavoro per lavoratore</h1>
                {
                    message && message.map((data, key) => (
                        <Alert variant={data.tipo} id={`message-${key}`} key={key}>
                            {data.descrizione}
                        </Alert>
                    ))}
                <Container className="">
                    <FiltriPerLavoratore setMessage={setMessage} filtroChanged={filtroChanged} lavoratori={lavoratori} />
                </Container>
                {filtro && <Container fluid>
                    <RipetiSettimana
                        lavoratore={filtro.lavoratore}
                        settimanariferimento={filtro.settimana}
                        setMessage = {setMessage}
                        settimana = {filtro.settimana}/>
                    <TabellaOrarioPerLavoratore
                        setMessage={setMessage}
                        sedi={sedi}
                        servizi={servizi}
                        lavoratore={filtro.lavoratore}
                        settimana={filtro.settimana}
                        lavoratori={lavoratori}
                    />
                </Container>}
            </div>
        </div>)
}

export default OrarioLavoroLavoratore;
