import { useEffect } from 'react';
import RigheSede from "./RigheSede.js";


function RigheTabellaGiorno(props) {


    useEffect(() => {
    }, []);


    return( 
        props.sedi && Object.keys(props.turni).map((sedeId, rowIndex) => {
            var sedeCompleta = props.sedi.find(sede => sede.id === sedeId); 
            const descrizioneSede = sedeCompleta.descrizione;
            var postazioni = sedeCompleta.postazioni;
            var nPostazioni = Object.keys(postazioni).length;   
            return (
                <RigheSede 
                    sedi={props.sedi}
                    servizi={props.servizi}
                    lavoratori={props.lavoratori}
                    sedeId={sedeId}
                    turni={props.turni[sedeId]}
                    index={`${props.index}${descrizioneSede}`}
                    setMessage={props.setMessage}
                    data={props.data}
                    nPostazioni = {nPostazioni}
                    rowIndex={rowIndex}
                />
            );
        }))        
}

export default RigheTabellaGiorno;