
import React, { useRef, useEffect, useState } from 'react'
import { getPostazioneDesc } from './turniHelper';
import { TurnoLavoratore } from "./TurnoLavoratore";
import { TotaleOre } from "./TotaleOre";
import { FaPlus } from 'react-icons/fa';
import Popup from 'reactjs-popup';
import moment from "moment";

function RigaTabellaGiorno(props) {

  const [sede, setSede] = useState(null)
  const [postazione, setPostazione] = useState(null)
  const [turniMattina, setTurniMattina] = useState(null)
  const [turniPomeriggio, setTurniPomeriggio] = useState(null)

  const ref = useRef();  
  const closeTooltip = () => ref.current.close();

  const rowClass = props.rowIndex % 2 === 0 ? 'lineaGrigia' : 'lineaBianca';

  useEffect(() => {
    setSede(props.sede);
    var postazioni = props.sede.postazioni;
    var descPostazione = getPostazioneDesc(postazioni, props.postazione);
    setPostazione(descPostazione);
    setTurniMattina(props.turni.mattina);
    setTurniPomeriggio(props.turni.pomeriggio);
  }, [props.turni, props.sede])


  const getLavoratoreTurno = (lavoratoreId) => {
    const filtered =  props.lavoratori.filter((lavoratore) => lavoratore.value === lavoratoreId);
    return filtered.length >0 ? filtered[0] : null;
  }

  const addTurnoMattina = (data) => {
    props.setMessage([])
    data.messaggi.map((value, key) => (
      props.setMessage(message => [...message, {
          descrizione: value.messaggio,
          tipo: value.tipo.toLowerCase()
      }])
    ))
    for( var i = 0; i < turniMattina.length; i++){ 
        if ( turniMattina[i].id === data.turno.id) {
          turniMattina.splice(i, 1); 
        }
    }
    turniMattina.push(data.turno);
    turniMattina.sort(function(a,b) {
        return a.inizio > b.inizio
    })
    closeTooltip();
}

const eliminaTurnoMattina = (data) => {
  props.setMessage([])
  data.messaggi.map((value, key) => (
      props.setMessage(message => [...message, 
          {descrizione: value.descrizione, tipo: value.tipo }])
  ))
  for( var i = 0; i < turniMattina.length; i++){ 
      if ( turniMattina[i].id === data.id) {
        turniMattina.splice(i, 1); 
      }
  }
  closeTooltip();
}

const addTurnoPomeriggio = (data) => {
  props.setMessage([])
  data.messaggi.map((value, key) => (
    props.setMessage(message => [...message, {
        descrizione: value.messaggio,
        tipo: value.tipo.toLowerCase()
    }])
  ))
  for( var i = 0; i < turniPomeriggio.length; i++){ 
      if ( turniPomeriggio[i].id === data.turno.id) {
        turniPomeriggio.splice(i, 1); 
      }
  }
  turniPomeriggio.push(data.turno);
  turniPomeriggio.sort(function(a,b) {
      return a.inizio > b.inizio
  })
  closeTooltip();
}

const eliminaTurnoPomeriggio = (data) => {
props.setMessage([])
data.messaggi.map((value, key) => (
    props.setMessage(message => [...message, 
        {descrizione: value.descrizione, tipo: value.tipo }])
))
for( var i = 0; i < turniPomeriggio.length; i++){ 
    if ( turniPomeriggio[i].id === data.id) {
      turniPomeriggio.splice(i, 1); 
    }
}
closeTooltip();
}

const formatTurno = (turno) => {
  const lavoratore = getLavoratoreTurno(turno.lavoratoreId);
  const label = lavoratore !== null ? lavoratore.label : "";
  return turno.inizio + "-"
      + turno.fine + " "
      + label + ": "
      + props.servizi[turno.servizioId][0]
}

  const getPopup = (trigger, turno, cssClass, index, mattina, addTurno, eliminaTurno) => {
    const lavoratore = getLavoratoreTurno(turno.lavoratoreId);
    return (<Popup
        ref={ref}
        trigger={<button className={cssClass} id={index} key={index}>{trigger}</button>}
        position="bottom center"
        modal>{
            close =>
                <TurnoLavoratore
                    id={turno.id}
                    sede={sede.id}
                    inizio={turno.inizio}
                    fine={turno.fine}
                    postazione={props.postazione}
                    servizio={turno.servizioId}
                    sedi={props.sedi}
                    servizi={props.servizi}
                    lavoratore={lavoratore}
                    giorno={props.data}
                    mattina={mattina}
                    action={addTurno}
                    eliminaAction={eliminaTurno}
                    close={close} 
                    lavoratori={props.lavoratori}
                    lavoratoreDisable={false}
                    postazioneDisable={true}
                    sedeDisable={true}
                />
        }
    </Popup>)
}


  return (
    sede && postazione && <tr className={rowClass}>
      {props.indexRowSpan === 0 && <td className="inEvidenza bordiSpessi" rowSpan={props.nPostazioni}>{sede.descrizione}</td>}
      <td className="inEvidenza bordiSpessi">{postazione}</td>
      <td className="bordiSpessi">
        {getPopup(<FaPlus />, {id: "", inizio: null, fine: null, sedeId: sede.id, postazione: postazione}, 'aggiungiTurno', props.index + "mattina", true, addTurnoMattina, eliminaTurnoMattina)}
        {turniMattina.map((turno, keyTurno) => (
          <div className='turno' key={keyTurno}>{
            getPopup(formatTurno(turno), turno, 'triggerTurno', props.index + "mattinaturno" + keyTurno, true, addTurnoMattina, eliminaTurnoMattina)
          }</div>
        ))}
      </td>
      <td className='orario bordiSpessi'>
        <TotaleOre
            turni={turniMattina}
            index ={moment(props.data).format('dddd')+props.index+"mattinatotore"}
        /></td>
      <td className='orario bordiSpessi'>
        {getPopup(<FaPlus />, {id: "", inizio: null, fine: null, sedeId: sede.id, postazione: postazione}, 'aggiungiTurno', props.index + "pomeriggio", false, addTurnoPomeriggio, eliminaTurnoPomeriggio)}
        {turniPomeriggio.map((turno, keyTurno) => (
          <div className='turno' key={keyTurno}>{
            getPopup(formatTurno(turno), turno, 'triggerTurno', props.index + "pomeriggioturno" + keyTurno, false, addTurnoPomeriggio, eliminaTurnoPomeriggio)
          }</div>
        ))}</td>
      <td className='orario bordiSpessi'>
        <TotaleOre
            turni={turniPomeriggio}
            index ={moment(props.data).format('dddd')+props.index+"pomeriggiototore"}
        /></td>
    </tr>
  )

}

export default RigaTabellaGiorno;