import './css/App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import { Switch, Route, BrowserRouter as Router} from 'react-router-dom';
import Login from "./AuthComponent/Login"
import LandingPage from "./LandingPage/Landingpage"
import Reset from './AuthComponent/reset';
import Header from './Header';
import Users from './Users/users';
import Organizzazioni from './Organizzazione/Organizzazioni';
import { useState, useMemo} from 'react';
import { MessageContext } from './contexts/MessageContext'
import RiepilogoGenerale from './riepilogogenerale';
import AddEditOrganization from './Organizzazione/AddEditOrganizzazione';
import Obiettivo from './obiettivo';
import OrarioLavoroLavoratore from './TurniLavoro/OrarioLavoroLavoratore';
import OrarioLavoroSede from './TurniLavoro/OrarioLavoroSede';
import OrarioLavoroSettimana from './TurniLavoro/OrarioLavoroSettimana';
import ReportOrarioLavoro from './TurniLavoro/ReportOrarioLavoro';
import CaricaFile from './Caricafile';
import { AssociazioneArea } from './Organizzazione/AssociazioneArea';
import area730 from './Aree/Area730';
import areaImu from './Aree/AreaImu'
import areaunico from './Aree/AreaUnico'
import areacondomini from './Aree/AreaCondomini'
import areasuccessioni from './Aree/AreaSuccessioni'
import arealocazioni from './Aree/AreaLocazioni'
import areavarie from './Aree/AreaVarie'
import fatturatosettimanale730 from './Campagna730/FatturatoSettimanale730'
import fatturatocumulativo730 from './Campagna730/FatturatoCumulativo730'
import numerosettimanale730 from './Campagna730/NumeroSettimanale730'
import numerosettimanale730inmodifica from './Campagna730/NumeroSettimanale730InModifica'
import numerocumulativo730 from './Campagna730/NumeroCumulativo730'
import riepiloghi from './Campagna730/Riepiloghi'
import riepilogototale from './Campagna730/RiepilogoTotale'
import produzionePerSede from './Campagna730/ProduzionePerSede'

import numerosettimanaleisee from './CampagnaIsee/NumeroSettimanaleIsee'
import numerocumulativoisee from './CampagnaIsee/NumeroCumulativoIsee';
import numerosettimanaleiseeinmodifica from './CampagnaIsee/NumeroSettimanaleIseeInModifica'
import riepiloghiisee from './CampagnaIsee/RiepiloghiISEE';
import GestioneLavoratori from './TurniLavoro/GestioneLavoratori';
import GestioneSedi from './TurniLavoro/GestioneSedi';
import GestioneServizi from './TurniLavoro/GestioneServizi';

function App() {

  const [message, setMessage] = useState({ tipo: "", descrizione: "" })
  const value = useMemo(() => ({ message, setMessage }), [message, setMessage]);

  const DefaultContainer = () => (
    <div>
        <Header base_url={process.env.REACT_APP_BASE_URL} />
        <Route exact path="/landingpage" component={LandingPage} />
        <Route exact path="/resetpassword" component={Reset} />
        <Route exact path="/gestioneutenti" component={Users} />
        <Route exact path="/gestioneorganizzazioni" component={Organizzazioni} />
        <Route exact path="/addorganizzazione"><AddEditOrganization edit={false}/></Route>
        <Route exact path="/editorganizzazione/"><AddEditOrganization edit={true}/></Route>
        <Route exact path="/associazionearea" component={AssociazioneArea} />
        <Route exact path="/logout" />

        <Route exact path="/riepilogogenerale" component={RiepilogoGenerale} />
        <Route exact path="/area730" component={area730} />
        <Route exact path="/areaimu" component={areaImu} />
        <Route exact path="/areaunico" component={areaunico} />
        <Route exact path="/areacondomini" component={areacondomini} />
        <Route exact path="/areasuccessioni" component={areasuccessioni} />
        <Route exact path="/arealocazioni" component={arealocazioni} />
        <Route exact path="/areavarie" component={areavarie} />
        <Route exact path="/obiettivo" component={Obiettivo} />
        <Route exact path="/caricafile" component={CaricaFile} />
        <Route exact path="/orariolavorolavoratore" component={OrarioLavoroLavoratore} />
        <Route exact path="/orariolavorosede" component={OrarioLavoroSede} />
        <Route exact path="/orariolavorosettimana" component={OrarioLavoroSettimana} />
        <Route exact path="/reportorariolavoro" component={ReportOrarioLavoro} />
        <Route exact path="/gestionelavoratori" component={GestioneLavoratori} />
        <Route exact path="/gestionesedi" component={GestioneSedi} />
        <Route exact path="/gestioneservizi" component={GestioneServizi} />

        <Route path="/fatturatosettimanale730" component={fatturatosettimanale730} />
        <Route path="/fatturatocumulativo730" component={fatturatocumulativo730}/>
        <Route path="/numerosettimanale730" component={numerosettimanale730}/>
        <Route path="/numerosettimanale730inmodifica" component={numerosettimanale730inmodifica}/>
        <Route path="/numerocumulativo730" component={numerocumulativo730}/> 
        <Route path="/riepiloghi" component={riepiloghi}/>
        <Route path="/riepilogototale" component={riepilogototale}/>
        <Route path="/produzionepersede" component={produzionePerSede}/>

        <Route path="/numerosettimanaleisee" component={numerosettimanaleisee}/>
        <Route path="/numerosettimanaleiseeinmodifica" component={numerosettimanaleiseeinmodifica}/>
        <Route path="/numerocumulativoisee" component={numerocumulativoisee}/>
        <Route path="/riepiloghiisee" component={riepiloghiisee}/>
    </div>
  )

  return (
    <div>
      <Router basename={process.env.REACT_APP_BASEAPP_NAME} >
        <MessageContext.Provider value={ value }>
          <Switch>
            <Route exact path="/" component={Login}></Route>
            <Route component={DefaultContainer}></Route>
          </Switch>
        </MessageContext.Provider>
      </Router>
    </div>
  );

}

export default App;
