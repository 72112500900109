import 'reactjs-popup/dist/index.css';
import { useEffect, useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { buildUrl } from '../helper/handler';
import { postToApi } from '../utilities/NetUtilities';

export function RipetiGiorno(props) {

    const [nomeOrganizzazione, setNomeOrganizzazione] = useState("");
    const [tipoOrganizzazione, setTipoOrganizzazione] = useState("");
    const [messaggiPopUp, setMessaggiPopUp] = useState([]);

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione")); 
        if(organizzazioneMap) {
            setNomeOrganizzazione(organizzazioneMap.nome);
            setTipoOrganizzazione(organizzazioneMap.tipo);
        }
    }, []);

    const giorni = ['lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'];
    
    const setMessageFromApi = (data) => {
        setMessaggiPopUp([])
        data.messaggi.map((value, key) => (
            setMessaggiPopUp(message => [...message, {
                descrizione: value.descrizione,
                tipo: value.tipo.toLowerCase()
            }])
        ))
    }
    
    const ripetiTurno = (data) => {
        props.setMessage([])
        data.messaggi.map((value, key) => (
            props.setMessage(message => [...message, {
                descrizione: value.messaggio,
                tipo: value.tipo.toLowerCase()
            }])
        ))
        props.aggiungiTurnoRipetuto(data.turno.giorno, data.turno, props.mattina)
        props.closeTooltip();
    }

    const ripeti = (event) => {
        event.preventDefault();
        const selectedDays = [];
        event.target.ripetiturno.forEach(day => {
            if (day.checked) {
                selectedDays.push(day.value);
            }
        });

        if (selectedDays.length > 0) {
            const params = {
                id: event.target.ripetiturnoid.value,
                giorni: selectedDays,
                settimana: event.target.ripetiturnosettimana.value,
                organizzazione : nomeOrganizzazione,
                tipo: tipoOrganizzazione
            };
            const url = buildUrl('ripetiTurno');
            postToApi(url, params, ripetiTurno, setMessageFromApi);
        } else {
            setMessaggiPopUp([{ descrizione: "scegliere i giorni in cui ripetere il turno", tipo: "danger" }]);
        }
    }

    return (
    <div className="formGroup popUp" id="ripetiturno"> {
        messaggiPopUp && messaggiPopUp.map((data, key) => (
            <Alert variant={data.tipo} id="message" key={key}>
                {data.descrizione}
            </Alert>))}
        <Form onSubmit={ripeti}>
            <Form.Group className="popUp">
            <Form.Label  className='inEvidenza isolato'>Scegliere i giorni in cui ripetere il turno:</Form.Label>
                <Form.Control
                    type="text"
                    value={props.turno.id}
                    hidden={true}
                    name='ripetiturnoid'/>
                <Form.Control
                    type="text"
                    value={props.settimana}
                    hidden={true}
                    name='ripetiturnosettimana'/>
                {giorni.map((value, index) => {
                    return (
                        <div className="inEvidenza row" key={index}>
                            <Form.Check
                                className="elementoCheckbox"
                                type="checkbox"
                                value={index}
                                name='ripetiturno'
                                id={'ripetiturno-' + value}
                            /><div className="elementoCheckbox">{value}</div></div>
                    )
                })}
            </Form.Group>
            <div className='buttonPopUp'>
                <Button className="btnGenerale" variant="flat" size="sm" id="ripeti"
                    type="submit">Ripeti</Button>
                <Button className="btnGenerale" variant="flat" size="sm" id="annulla"
                    onClick={() => props.close()}>Annulla</Button>
            </div>
        </Form>
    </div>)
}
