

import { getSede } from './turniHelper';
import { useEffect, useState } from 'react';
import RigaTabellaGiorno from './RigaTabellaGiorno';

function RigheSede(props) {

    const [sede, setSede] = useState(null)

    useEffect(() => {
        setSede(getSede(props.sedi, props.sedeId));
    }, [props.sedi, props.sedeId]);

    return (
        sede && props.turni && Object.keys(props.turni).map((postazione, index) => {
            return (
                <RigaTabellaGiorno
                    turni={props.turni[postazione]}
                    sede={sede}
                    postazione={postazione}
                    index={props.index + postazione}
                    setMessage={props.setMessage}
                    data={props.data}
                    sedi={props.sedi}
                    servizi={props.servizi}
                    lavoratori={props.lavoratori}
                    nPostazioni = {props.nPostazioni}
                    indexRowSpan = {index}
                    rowIndex={props.rowIndex}
                />
            )
        })
    )
}

export default RigheSede;