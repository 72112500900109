import React, { useEffect, useState } from 'react';
import { getFromApi, postToApi } from '../utilities/NetUtilities';
import { buildUrl } from '../helper/handler';
import { Container, Alert, Table, Modal, Button, Row, Col } from "react-bootstrap";
import LavoratoreForm from './LavoratoreForm';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { SpinnerComp } from '../helper/spinner';

function GestioneLavoratori() {

    const [lavoratori, setLavoratori] = useState([]);
    const [message, setMessage] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentLavoratore, setCurrentLavoratore] = useState(null);
    const [organizzazione, setOrganizzazione] = useState({});
    const [lavoratoreToDelete, setLavoratoreToDelete] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        const setLavoratoriFromApi = (data) => {
            setLavoratori(sortLavoratori(data.lavoratori));
            setLoading(false);
        };

        if (organizzazioneMap) {
            getFromApi(buildUrl('lavoratori', { organizzazione: organizzazioneMap.nome, tipo: organizzazioneMap.tipo }), setLavoratoriFromApi, setMessageFromApi);
            setOrganizzazione(organizzazioneMap);
        }
    }, []);

    const setMessageFromApi = (data) => {
        setMessage([]);
        data.messaggi.map((value, _) => (
            setMessage(message => [...message, {
                descrizione: value.descrizione,
                tipo: value.tipo.toLowerCase()
            }])
        ));
        setShowModal(false);
    };

    const handleShowModal = (lavoratore) => {
        setMessage([]);
        setCurrentLavoratore(lavoratore);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setCurrentLavoratore(null);
        setShowModal(false);
    };

    const handleShowConfirmModal = (lavoratore) => {
        setMessage([]);
        setLavoratoreToDelete(lavoratore);
        setShowConfirmModal(true);
    };

    const handleCloseConfirmModal = () => {
        setLavoratoreToDelete({});
        setShowConfirmModal(false);
    };

    const action = (data) => {
        setMessage([]);
        data.messaggi.forEach((value) => {
            setMessage(prevMessage => [...prevMessage, {
                descrizione: value.descrizione,
                tipo: value.tipo.toLowerCase()
            }]);
        });
        refreshLavoratori(data.lavoratore);
        setShowModal(false);
    };

    const refreshLavoratori = (lavoratore) => {
        if (lavoratore) {
            setLavoratori(prevLavoratori => {
                const existingLavoratoreIndex = prevLavoratori.findIndex(lav => lav.id === lavoratore.id);
                let updatedLavoratori;
                if (existingLavoratoreIndex !== -1) {
                    updatedLavoratori = [...prevLavoratori];
                    updatedLavoratori[existingLavoratoreIndex] = lavoratore;
                } else {
                    updatedLavoratori = [...prevLavoratori, lavoratore];
                }
                return sortLavoratori(updatedLavoratori);
            });
        }
    };

    const handleSaveLavoratore = (lavoratore) => {
        const lavoratoreUpdated = {
            ...lavoratore
        };
        if (!currentLavoratore) {
            lavoratoreUpdated.organizzazione = organizzazione.nome;
        }
        const url = buildUrl(currentLavoratore ? 'editLavoratore' : 'addLavoratore');
        postToApi(url, lavoratoreUpdated, action, setMessageFromApi);
    };

    const okActionDelete = (data) => {
        setLavoratori(prevLavoratori => prevLavoratori.filter(lav => lav.id !== lavoratoreToDelete.id));
        setMessageFromApi(data);
    }

    const handleDeleteLavoratore = () => {
        const url = buildUrl('deleteLavoratore');
        postToApi(url, lavoratoreToDelete, okActionDelete, setMessageFromApi);
        handleCloseConfirmModal();
    };

    const sortLavoratori = (lavoratori) => {
        return lavoratori.sort((a, b) => {
            if (a.cognome === b.cognome) {
                return a.nome.localeCompare(b.nome);
            }
            return a.cognome.localeCompare(b.cognome);
        });
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <SpinnerComp />
            </div>
        );
    }

    return (
        <div className='main' role="main">
            <div>
                <h1 className="d-flex justify-content-center inEvidenza" id="title_home">Gestione lavoratori</h1>
                {
                    message && message.map((data, key) => (
                        <Alert variant={data.tipo} id="message" key={key}>
                            {data.descrizione}
                        </Alert>
                    ))
                }
                <Container>
                    <FaPlus id="aggiungi_lavoratore" title='aggiungi lavoratore' className='actionIcon bottom-space-ridotto' onClick={() => handleShowModal(null)} />
                    {lavoratori.length > 0 ? (
                        <Table id="tabella_lavoratori" striped bordered hover>
                            <thead>
                                <tr>
                                    <th className="inEvidenza App">nome</th>
                                    <th className="inEvidenza App">cognome</th>
                                    <th className="inEvidenza App">lunedì</th>
                                    <th className="inEvidenza App">martedì</th>
                                    <th className="inEvidenza App">mercoledì</th>
                                    <th className="inEvidenza App">giovedì</th>
                                    <th className="inEvidenza App">venerdì</th>
                                    <th className="inEvidenza App">sabato</th>
                                    <th className="inEvidenza App">ore settimanali</th>
                                    <th className="inEvidenza App">azioni</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lavoratori.map((lavoratore, index) => (
                                    <tr key={lavoratore.id}>
                                        <td>{lavoratore.nome}</td>
                                        <td>{lavoratore.cognome}</td>
                                        <td className="number">{lavoratore.mapOrePerGiorno.lunedì}</td>
                                        <td className="number">{lavoratore.mapOrePerGiorno.martedì}</td>
                                        <td className="number">{lavoratore.mapOrePerGiorno.mercoledì}</td>
                                        <td className="number">{lavoratore.mapOrePerGiorno.giovedì}</td>
                                        <td className="number">{lavoratore.mapOrePerGiorno.venerdì}</td>
                                        <td className="number">{lavoratore.mapOrePerGiorno.sabato}</td>
                                        <td className="number">{lavoratore.totaleOreSettimana}</td>
                                        <td>
                                            <div className="actionIconContainer">
                                                <FaEdit title='modifica lavoratore' id={`edit-lavoratore-${index}`} className='actionIcon' onClick={() => handleShowModal(lavoratore)} />
                                                <FaTrash title='elimina lavoratore' id={`delete-lavoratore-${index}`} className='actionIcon' onClick={() => handleShowConfirmModal(lavoratore)} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <Row className="justify-content-center">
                            <Col xs={12} className="text-center">
                                <p>Non ci sono lavoratori.</p>
                            </Col>
                        </Row>
                    )}
                    <FaPlus className='actionIcon' onClick={() => handleShowModal(null)} />
                </Container>
            </div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="title_popup">{currentLavoratore ? 'Modifica Lavoratore' : 'Aggiungi Lavoratore'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LavoratoreForm lavoratore={currentLavoratore} onSave={handleSaveLavoratore} onClose={handleCloseModal} />
                </Modal.Body>
            </Modal>

            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="title_popup">Conferma Eliminazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>Sei sicuro di voler eliminare il lavoratore '{lavoratoreToDelete.nome + " " + lavoratoreToDelete.cognome}' ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" id="annulla_elimina" onClick={handleCloseConfirmModal}>
                        Annulla
                    </Button>
                    <Button variant="danger" id="elimina" onClick={handleDeleteLavoratore}>
                        Elimina
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default GestioneLavoratori;
