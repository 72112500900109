import React, { useEffect, useState } from 'react';
import {daMinutiAOre} from './turniHelper';
import {Button} from "react-bootstrap";

function TabellaSede(props) {

    const [tabellaSede, setTabellaSede] = useState(null)
    const [totMinutiSede, setTotMinutiSede] = useState({})

    useEffect(() => {
        setTabellaSede(props.tabellaSede)
        setTotMinutiSede(props.minutiTotSede);
    }, [props.tabellaSede])

    const printRighe = (tabellaServizi, sede, indexSede, rowClass) =>{
        const nServizi = Object.keys(tabellaServizi).length;
        return(
            Object.keys(tabellaServizi).map((servizio, indexServizio) => {
            var minuti = tabellaServizi[servizio]
            return (
            <tr id={indexSede-indexServizio} key={indexSede + '-' + indexServizio} className={`cellaSpaziosa ${rowClass}`}>
                {indexServizio===0 && <td rowSpan={nServizi+1 } id={'sede-' + indexSede + '-' + indexServizio} className="inEvidenza cellaSpaziosa bordiSpessi">{sede}</td>}
                <td className='cellaSpaziosa bordiSpessi' id={'servizio-' + indexSede + '-' + indexServizio}>{servizio}</td>
                <td className="center cellaSpaziosa bordiSpessi" id={'ore-' + indexSede + '-' + indexServizio}>{daMinutiAOre(minuti)}</td>
            </tr>
            )}))
        
    }

    const printTotaleSede = (sede, indexSede, rowClass) => {
        return (<tr key={'totale-' + indexSede}>
                <td className={`inEvidenza right cellaSpaziosa bordiSpessi ${rowClass}`}>totale ore</td>
                <td className={`inEvidenza center cellaSpaziosa bordiSpessi ${rowClass}`} id={'totore-' + indexSede}>{daMinutiAOre(totMinutiSede[sede])}</td></tr>
        )
    }

    const printTabella = () => {
        return (
            Object.keys(tabellaSede).map((sede, indexSede) => {
                const rowClass = indexSede % 2 === 0 ? 'lineaGrigia' : 'lineaBianca';
                var tabellaServizi = tabellaSede[sede];
                return (
                    <React.Fragment key={`table-${indexSede}`}>
                        {printRighe(tabellaServizi, sede, indexSede, rowClass)}
                        {printTotaleSede(sede, indexSede, rowClass)}
                    </React.Fragment>
                );
            })
        );
    }

    return (
        <div id="tabellasede" className='table-responsive'>
                <h2 className='inEvidenza'>Totale zona   
                    <Button data-toggle="tooltip" title="Download CSV" className='btnGenerale' variant="flat" size="sm" id="csvzona"
                href={props.urlCsv}>scarica</Button></h2>
              
            {tabellaSede && <table className="table-bordered bottom-space " >
                <thead className="App">
                    <tr key="intestazione">
                        <th className="inEvidenza cellaSpaziosa bordiSpessi">sede</th>
                        <th className="inEvidenza cellaSpaziosa bordiSpessi">servizio</th>
                        <th className="inEvidenza cellaSpaziosa bordiSpessi">totale (hh:mm)</th>
                    </tr>
                </thead>
                <tbody>
                {printTabella()}
                </tbody>
            </table>}
        </div>
    )
}

export default TabellaSede;