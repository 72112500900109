import React, { useEffect, useState } from 'react'
import { buildUrl } from '../helper/handler';
import { getFromApi } from '../utilities/NetUtilities';
import { getPostazioneDesc, getSede } from './turniHelper';
import { TurniGiornoSede } from "./TurniGiornoSede";
import { TotaleOre } from "./TotaleOre";
import moment from "moment";

function TabellaOrarioPerSede(props) {

    const [sedi, setSedi] = useState();
    const [turni, setTurni] = useState(null)
    const [sede, setSede] = useState(null)
    const [settimana, setSettimana] = useState(null)


    const isLoaded = () => sede === props.sede.value && settimana === props.settimana;

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        const setSediFromApi = (data) => {
            setSedi(data.sedi);
        }
        const setMessageFromApi = (data) => {
            props.setMessage(message => [...message, { tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione }])
        }
        const setTurniFromApi = (data) => {
            setTurni(data.turni);
            setSede(props.sede.value);
            setSettimana(props.settimana);
        }
        const getTurni = (sede, settimana) => {
            if (sede && settimana && organizzazioneMap) {
                const url = buildUrl('turniPerSede', { sede: sede, settimana: settimana, organizzazione: organizzazioneMap.nome, tipo: organizzazioneMap.tipo });
                getFromApi(url, setTurniFromApi, setMessageFromApi);
            }
        }
        if (organizzazioneMap) {
            const url = buildUrl('sedi', { organizzazione: organizzazioneMap.nome, tipo: organizzazioneMap.tipo });
            getFromApi(url, setSediFromApi, setMessageFromApi);
            getTurni(props.sede.value, props.settimana);
        }
    }, [props]);



    const printTurniPerSede = (data, turniGiornoSede, rowIndex) => {
        var sedeCompleta = getSede(sedi, sede);
        var postazioni = sedeCompleta.postazioni;
        var nPostazioni = Object.keys(postazioni).length;
        return (Object.keys(turniGiornoSede).map((postazione, index) => {
            var descPostazione = getPostazioneDesc(postazioni, postazione);
            var turniGiorno = turniGiornoSede[postazione]
            const rowClass = rowIndex % 2 === 0 ? 'lineaGrigia' : 'lineaBianca';
            return (
                <tr key={`${data}-${descPostazione}`} className={rowClass}>
                    {index === 0 &&  <td className="inEvidenza bordiSpessi" rowSpan={nPostazioni}>{moment(data).format('dddd DD MMMM')}</td>}
                    <td className="inEvidenza bordiSpessi">{descPostazione}</td>
                    <td className="bordiSpessi"><TurniGiornoSede
                        setMessage={props.setMessage}
                        sedi={sedi}
                        sede={props.sede}
                        postazione={postazione}
                        servizi={props.servizi}
                        giorno={data}
                        mattina={true}
                        turni={turniGiorno.mattina}
                        lavoratoreDisable={false}
                        postazioneDisable={true}
                        sedeDisable={true}
                        index={moment(data).format('dddd') + index + 'mattina'}
                        lavoratori={props.lavoratori}
                    /></td>
                    <td className='orario bordiSpessi'>
                        <TotaleOre
                            turni={turniGiorno.mattina}
                            index={moment(data).format('dddd') + index + "mattinatotore"}
                        />
                    </td>
                    <td className="bordiSpessi"><TurniGiornoSede
                        setMessage={props.setMessage}
                        sedi={sedi}
                        sede={props.sede}
                        postazione={postazione}
                        servizi={props.servizi}
                        giorno={data}
                        mattina={false}
                        turni={turniGiorno.pomeriggio}
                        lavoratoreDisable={false}
                        postazioneDisable={true}
                        sedeDisable={true}
                        index={moment(data).format('dddd') + index + 'pomeriggio'}
                        lavoratori={props.lavoratori}
                    ></TurniGiornoSede></td>
                    <td className='orario bordiSpessi'>
                        <TotaleOre
                            turni={turniGiorno.pomeriggio}
                            index={moment(data).format('dddd') + index + "pomeriggiototore"}
                        />
                    </td>
                </tr>
            )
        })
        )
    }

    const printTurni = () => {
        return (
            Object.keys(turni).map((data, index) => {
                var turniGiornoSede = turni[data];
                const result = printTurniPerSede(data, turniGiornoSede, index);
                return result;
            })
        )
    }

    return (
        <table className="table table-bordered space bordiSpessi" key="tabellapersede">
            <thead className="App tableTurniLavoro">
                <tr key="intestazione">
                    <th className="inEvidenza bordiSpessi">giorno</th>
                    <th className="inEvidenza bordiSpessi">postazione</th>
                    <th className="inEvidenza bordiSpessi">mattina</th>
                    <th className="inEvidenza bordiSpessi">totale (hh:mm)</th>
                    <th className="inEvidenza bordiSpessi">pomeriggio</th>
                    <th className="inEvidenza bordiSpessi">totale (hh:mm)</th>
                </tr>
            </thead>
            <tbody>
                {isLoaded() && turni && printTurni()}
            </tbody>
        </table>
    )

}

export default TabellaOrarioPerSede;