import { Button } from 'react-bootstrap'
import { buildUrl } from '../helper/handler';

export const getDatiTabella = (
        dati, numTabella, nome, riepilogogenerale, inizio, fine, zona, nomeorganizzazione, tipoOrg, tutti) => {
    var datiTabellaFatturato = [];
    var datiTabellaMedia = [];
    var datiTabellaVariazione = [];
    var datiTabellaMedia730 = [];
    var datiRiepilogoGenerale = [];
    var errore = [];
    var optionsZone = [];
    try {
        if (riepilogogenerale) {
            elaboraDatiRiepilogoGenerale(dati, datiRiepilogoGenerale, inizio, fine, zona, nomeorganizzazione, tipoOrg, tutti);
        } else {
            datiTabellaMedia = elaboraDatiMedia(
                dati, nome, "mediafatturato", inizio, fine, zona, nomeorganizzazione, tipoOrg, tutti);
            elaboraDatiVariazione(
                dati, datiTabellaVariazione, nome, inizio, fine, zona, nomeorganizzazione, tipoOrg, tutti);
            if (numTabella >= 3) {
                elaboraDatiFatturato(
                    dati, datiTabellaFatturato, nome, inizio, fine, zona, nomeorganizzazione, tipoOrg, tutti);
                if (numTabella >= 4)
                    datiTabellaMedia730 = elaboraDatiMedia(
                        dati, nome, "media730", inizio, fine, zona, nomeorganizzazione, tipoOrg, tutti);
            }
        }
        elaboraOpzioniZone(dati, optionsZone)
    } catch (error) {
        errore.push("Non è stato possibile visualizzare le tabelle, se il problema persiste contattare l'assistenza")
    }
    return {
        datiTabellaFatturato,
        datiTabellaMedia,
        datiTabellaVariazione,
        datiTabellaMedia730,
        datiRiepilogoGenerale,
        errore,
        optionsZone
    };
};

function elaboraOpzioniZone(dati, optionsZone) {
    var zone = dati.zone
    Object.keys(zone).forEach(zona => {
        var option = {}
        option.label = zone[zona]
        option.value = zona
        optionsZone.push(option)
    })
};

function elaboraDatiFatturato(dati, datiTabellaFatturato, nome, inizio, fine, zona, nomeorganizzazione, tipo, tutti) {
    console.log("datiFatturato")
    const datitabella = {};
    const rows = [];
    const intestazione = [];
    const fatturato = dati.fatturato
    const colonne = fatturato.intestazioni;
    colonne.forEach(element => {
        intestazione.push(<th className="intestazioni thFixed" key={element.replace(/ /g, '')}
            id={element.replace(/ /g, '')}>{element}</th>)
    });
    fatturato.righe.forEach((riga) => {
        const row = [];
        const area = riga.area;
        row.push(<td className="intestazioni" key={area} id={area}>{area}</td>);
        Object.keys(riga.anni).forEach((anno, index) => {
            var fatturato = riga.anni[anno].fatturato.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            var numeroLinee = riga.anni[anno].numero_linee.toLocaleString('it-IT');
            var media = riga.anni[anno].media.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            row.push(<td className="number" key={area.replace(/ /g, '') + "-fatturato-" + anno}
                id={area.replace(/ /g, '') + "-fatturato-" + anno}>{fatturato}</td>);
            row.push(<td className="number" key={area.replace(/ /g, '') + "-numeroLinea-" + anno}
                id={area.replace(/ /g, '') + "-numeroLinea-" + anno}>{numeroLinee}</td>);
            row.push(<td className="number" key={area.replace(/ /g, '') + "-media-" + anno}
                id={area.replace(/ /g, '') + "-media-" + anno}>{media}</td>);
        });
        rows.push(row);
    });
    const totali = fatturato.totali;
    const row = [];
    row.push(<td className="intestazioni" key={"totali_fatturato"}>totali</td>);
    Object.keys(totali.anni).forEach(anno => {
        var fatturato = totali.anni[anno].fatturato.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        var numeroLinee = totali.anni[anno].numero_linee.toLocaleString('it-IT');
        var media = totali.anni[anno].media.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        row.push(<td className="number" key={"totale-fatturato-" + anno}
            id={"totale-fatturato-" + anno}>{fatturato}</td>);
        row.push(<td className="number" key={"totale-numeroLinee-" + anno}
            id={"totale-numeroLinee-" + anno}>{numeroLinee}</td>);
        row.push(<td className="number" key={"totale-media-" + anno}
            id={"totale-media-" + anno}>{media}</td>);
    });
    rows.push(row);
    datitabella.intestazione = intestazione;
    datitabella.rows = rows;
    datitabella.nome = "Fatturato " + nome

    const paramsObject = {
        tabella: "fatturato_" + nome.toLowerCase(),
        start: inizio,
        end: fine,
        zona: zona,
        organizzazione: nomeorganizzazione,
        tipo: tipo,
        tutti: tutti
    }
    const url = buildUrl('downloadcsv', paramsObject)

   /*  const url = process.env.REACT_APP_BASE_URL + "downloadcsv?tabella=fatturato_" + nome.toLowerCase() +
        "&start=" + inizio + "&end=" + fine + "&zona=" + zona + "&organizzazione=" + nomeorganizzazione + "&tipo=" + tipo +  "&tutti=" + tutti; */
    datitabella.button = <div id="csv" className="downloadcsv">
        <Button data-toggle="tooltip" title="Download CSV" size='sm' className='btnGenerale' variant="flat"
            href={url} >scarica</Button>
    </div>
    datiTabellaFatturato.push(datitabella);
    console.log("datiFatturatoEnd", datiTabellaFatturato)
}

function elaboraDatiMedia(dati, nome, tipo, inizio, fine, zona, nomeorganizzazione, tipoOrg, tutti) {
    const tabellaMedia = [];
    const datitabella = {};
    const rows = [];
    const intestazione = [];
    var media = dati.mediaFattura
    var nomeStart = "Media Fatturato "
    var nomeEnd = ""
    var link = "media_" + nome.toLowerCase();
    console.log("nome", nome)
    if (nome === "730") {
        nomeEnd = " Per Numero Linee Fattura"
    }
    if (tipo === "media730") {
        media = dati.media730
        nomeEnd = " per numero 730"
        link = "media730_" + nome.toLowerCase();
    }
    const colonne = media.intestazioni;
    colonne.forEach(element => {
        intestazione.push(<th className="intestazioni thFixed" key={tipo+"_"+element.replace(/ /g, '')}
            id={element.replace(/ /g, '')}>
            {element}</th>)
    });
    media.righe.forEach((riga) => {
        const row = [];
        const anno = riga.anno;
        const totale = riga.totale.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        const numeroLinee = riga.numero_linee.toLocaleString('it-IT');
        const media = riga.media.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        row.push(<td className="intestazioni" key={tipo + "-" + anno}
            id={tipo + "-" + anno}>{anno}</td>);
        row.push(<td className="number" key={tipo + "-" + anno + "-totale"}
            id={tipo + "-" + anno + "-totale"}>{totale}</td>);
        row.push(<td className="number" key={tipo + "-" + anno + "-numeroLinea"}
            id={tipo + "-" + anno + "-numeroLinea"}>{numeroLinee}</td>);
        row.push(<td className="number" key={tipo + "-" + anno + "-media"}
            id={tipo + "-" + anno + "-media"}>{media}</td>);
        rows.push(row);
    });
    datitabella.intestazione = intestazione;
    datitabella.rows = rows;
    datitabella.nome = nomeStart + nome + nomeEnd

    const paramsObject = {
        tabella: link,
        start: inizio,
        end: fine,
        zona: zona,
        organizzazione: nomeorganizzazione,
        tipo: tipoOrg,
        tutti: tutti
    }
    const url = buildUrl('downloadcsv', paramsObject)

 /*    const url = process.env.REACT_APP_BASE_URL + "downloadcsv?tabella=" + link +
        "&start=" + inizio + "&end=" + fine + "&zona=" + zona + "&organizzazione=" + nomeorganizzazione + "&tipo=" + tipoOrg +  "&tutti=" + tutti; */
    datitabella.button = <div id="csv" className="downloadcsv">
        <Button data-toggle="tooltip" title="Download CSV"  size='sm' className='btnGenerale' variant="flat"
            href={url} id={"download_" + link}>scarica</Button>
    </div>
    tabellaMedia.push(datitabella);
    return tabellaMedia
}

function elaboraDatiVariazione(dati, datiTabellaVariazione, nome, inizio, fine, zona, nomeorganizzazione, tipoOrg, tutti) {
    const datitabella = {};
    const rows = [];
    const intestazione = [];
    const variazione = dati.variazione
    const colonne = variazione.intestazioni;
    colonne.forEach(element => {
        intestazione.push(<th className="intestazioni thFixed" key={element.replace(/ /g, '')}
            id={element.replace(/ /g, '')}>{element}</th>)
    });
    variazione.righe.forEach((riga, index) => {
        const row = [];
        const descrizione = riga.descrizione;
        const variazione_percentuale = riga.variazione_percentuale.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        const variazione_percentuale_media = riga.variazione_percentuale_media.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        row.push(<td className="intestazioni" key={descrizione.replace(/ /g, '')}
            id={descrizione.replace(/ /g, '')}>{descrizione}</td>);
        row.push(<td className="number" key={"variazione-percentuale-fatturato-" + index}
            id={"variazione-percentuale-fatturato-" + index}>{variazione_percentuale + '%'}</td>);
        row.push(<td className="number" key={"variazione-percentuale-media-fatturato-" + index}
            id={"variazione-percentuale-media-fatturato-" + index}>{variazione_percentuale_media + '%'}</td>);
        if (nome === "730") {
            const variazione_percentuale_media_730 = riga.variazione_percentuale_media_730.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            row.push(<td className="number" key={"variazione-percentuale-media-730-" + index}
            id={"variazione-percentuale-media-730-" + index}>{variazione_percentuale_media_730 + '%'}</td>);
        }
        rows.push(row);
    });
    datitabella.intestazione = intestazione;
    datitabella.rows = rows;
    datitabella.nome = "Variazione " + nome

    const paramsObject = {
        tabella: "variazioni_" + nome.toLowerCase(),
        start: inizio,
        end: fine,
        zona: zona,
        organizzazione: nomeorganizzazione,
        tipo: tipoOrg,
        tutti: tutti
    }
    const url = buildUrl('downloadcsv', paramsObject)

   /*  const url = process.env.REACT_APP_BASE_URL + "downloadcsv?tabella=variazioni_" + nome.toLowerCase() +
        "&start=" + inizio + "&end=" + fine + "&zona=" + zona + "&organizzazione=" + nomeorganizzazione + "&tipo=" + tipoOrg +  "&tutti=" + tutti; */
    datitabella.button = <div id="csv" className="downloadcsv">
        <Button data-toggle="tooltip" title="Download CSV"  size='sm' className='btnGenerale' variant="flat"
            href={url} id={"download_variazione_" + nome}>scarica</Button>
    </div>
    datiTabellaVariazione.push(datitabella);
}

function elaboraDatiRiepilogoGenerale(
        datiservizio, datiRiepilogoGenerale, inizio, fine, zona, nomeorganizzazione, tipo, tutti) {
    const datitabella = {};
    const rows = [];
    const intestazione = [];
    const colonne = datiservizio.intestazioni;
    colonne.forEach(element => {
        intestazione.push(<th className="intestazioni th" key ={element}
            id={element.replace(/ /g, '')}>{element}</th>)
    });
    var righeSorted = [...datiservizio.righe].sort((a, b) => a.codice_tariffario - b.codice_tariffario)
    righeSorted.forEach((riga, indexRiga) => {
        const row = [];
        const codice_ricavo = riga.codice_ricavo;
        row.push(<td className="intestazioni"  key={"codiceRicavo-" + indexRiga}
            id={"codiceRicavo-" + indexRiga}>{codice_ricavo}</td>);
        const codice_tariffario = riga.codice_tariffario;
        row.push(<td className="intestazioni"  key={"codiceTariffario-" + indexRiga}
            id={"codiceTariffario-" + indexRiga}>{codice_tariffario}</td>);
        const descrizione = riga.descrizione;
        row.push(<td key={"desrizione-" + indexRiga} id={"desrizione-" + indexRiga}>{descrizione}</td>);
        Object.keys(riga.anni).forEach((anno) => {
            var fatturato = riga.anni[anno].fatturato.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            var numeroLinee = riga.anni[anno].numero_linee.toLocaleString('it-IT');
            row.push(<td className="number" key={"fatturato-" + anno + "-" + indexRiga}
                id={"fatturato-" + anno + "-" + indexRiga}>{fatturato}</td>);
            row.push(<td className="number" key={"numeroLinea-" + anno + "-" + indexRiga}
                id={"numeroLinea-" + anno + "-" + indexRiga}>{numeroLinee}</td>);
        });
        rows.push(row);
    });
    const totali = datiservizio.totali;
    const row = [];
    row.push(<td className="intestazioni" colSpan="3" key="totali">totali</td>);
    Object.keys(totali.anni).forEach((anno) => {
        var fatturato = totali.anni[anno].fatturato.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        var numeroLinee = totali.anni[anno].numero_linee.toLocaleString('it-IT');
        row.push(<td className="number" key={"totaleFatturato-" + anno}
           id={"totaleFatturato-" + anno}>{fatturato}</td>);
        row.push(<td className="number" key={"totaleNumero-" + anno}
            id={"totaleNumero-" + anno}>{numeroLinee}</td>);
    });
    rows.push(row);
    datitabella.intestazione = intestazione;
    datitabella.rows = rows;
    datitabella.nome = "Fatturato per codice tariffario"

    const paramsObject = {
        tabella: "fatturato_per_codice_tariffario",
        start: inizio,
        end: fine,
        zona: zona,
        organizzazione: nomeorganizzazione,
        tipo: tipo,
        tutti: tutti
    }
    const url = buildUrl('downloadcsv', paramsObject)

  /*   const url = process.env.REACT_APP_BASE_URL +
        "downloadcsv?tabella=fatturato_per_codice_tariffario&organizzazione=" + nomeorganizzazione + "&tipo=" + tipo +  "&tutti=" + tutti +
        "&start=" + inizio + "&end=" + fine + "&zona=" + zona ; */
    datitabella.button = <div id="csv" className="downloadcsv">
        <Button data-toggle="tooltip" title="Download CSV" size='sm' className="btnGenerale" variant="flat"
            href={url} id={"download_RiepilogoGenerale"}>scarica</Button>
    </div>
    console.log("dati Tabella", datitabella)
    datiRiepilogoGenerale.push(datitabella);
}