
import { useEffect } from 'react';
import RigheTabellaGiorno from "./RigheTabellaGiorno.js";

function TabellaGiorno(props) {


    useEffect(() => {
    }, []);


    return (
        <div id={props.id}>
            <table className="table table-bordered space tabellagiornaliera bordiSpessi" >
            <thead className="App tableTurniLavoro">
                    <tr key="intestazione">
                        <th className="inEvidenza bordiSpessi">sede</th>
                        <th className="inEvidenza bordiSpessi">postazione</th>
                        <th className="inEvidenza bordiSpessi">mattina</th>
                        <th className="inEvidenza bordiSpessi">totale (hh:mm)</th>
                        <th className="inEvidenza bordiSpessi">pomeriggio</th>
                        <th className="inEvidenza bordiSpessi">totale (hh:mm)</th>
                    </tr>
                </thead>
                <tbody>
                    <RigheTabellaGiorno
                        turni={props.turni}
                        sedi={props.sedi}
                        servizi={props.servizi}
                        lavoratori={props.lavoratori}
                        setMessage={props.setMessage}
                        data={props.data}
                        index={props.id}
                    />
                </tbody>
            </table>
        </div>)
}


export default TabellaGiorno;