
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { TurnoLavoratore } from "./TurnoLavoratore";
import { FaPlus } from 'react-icons/fa';
import { useRef, useState } from 'react';

export function TurniGiornoSede(props) {

    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    const getSedeTurno = (sedeId) => {
        const filtered = props.sedi.filter((sede) => sede.id === sedeId);
        return filtered.length > 0 ? filtered[0].id : "";
    }
    const [sede] = useState(getSedeTurno(props.sede.value))

    const addTurno = (data) => {
        props.setMessage([])
        data.messaggi.map((value, key) => (
            props.setMessage(message => [...message,
            { descrizione: value.messaggio, tipo: value.tipo.toLowerCase() }])
        ))
        for (var i = 0; i < props.turni.length; i++) {
            if (props.turni[i].id === data.turno.id) {
                props.turni.splice(i, 1);
            }
        }
        props.turni.push(data.turno);
        props.turni.sort(function (a, b) {
            return a.inizio > b.inizio
        })
        closeTooltip();
    }

    const eliminaTurno = (data) => {
        props.setMessage([])
        data.messaggi.map((value, key) => (
            props.setMessage(message => [...message,
            { descrizione: value.messaggio, tipo: value.tipo.toLowerCase() }])
        ))
        for (var i = 0; i < props.turni.length; i++) {
            if (props.turni[i].id === data.id) {
                props.turni.splice(i, 1);
            }
        }
        closeTooltip();
    }

    const formatServizio = (servizio) => {
        return props.servizi[servizio][0];
    }

    const formatTurno = (turno) => {
        const lavoratore = getLavoratoreTurno(turno.lavoratoreId);
        const label = lavoratore !== null ? lavoratore.label : "";
        return turno.inizio + "-"
            + turno.fine + " "
            + label + ": "
            + formatServizio(turno.servizioId)
    }

    const getLavoratoreTurno = (lavoratoreId) => {
        const filtered = props.lavoratori.filter((lavoratore) => lavoratore.value === lavoratoreId);
        return filtered.length > 0 ? filtered[0] : null;
    }

    const getClassForServizio = (servizioId) => {
        return servizioId ? { backgroundColor: props.servizi[servizioId][1] } : {};
    };

    const getPopup = (trigger, turno, cssClass, index) => {
        const lavoratore = getLavoratoreTurno(turno.lavoratoreId);
        return (<Popup
            ref={ref}
            trigger={<button className={`${cssClass}`} style={getClassForServizio(turno.servizioId)} id={index} key={index}>{trigger}</button>}
            position="bottom center"
            modal>{
                close =>
                    <TurnoLavoratore
                        id={turno.id}
                        sede={turno.sedeId}
                        inizio={turno.inizio}
                        fine={turno.fine}
                        postazione={turno.postazione}
                        servizio={turno.servizioId}
                        sedi={props.sedi}
                        servizi={props.servizi}
                        lavoratore={lavoratore}
                        giorno={props.giorno}
                        mattina={props.mattina}
                        action={addTurno}
                        eliminaAction={eliminaTurno}
                        close={close}
                        lavoratori={props.lavoratori}
                        lavoratoreDisable={props.lavoratoreDisable}
                        postazioneDisable={props.postazioneDisable}
                        sedeDisable={props.sedeDisable}
                    />
            }
        </Popup>)
    }

    return (<>
        {
            getPopup(
                <FaPlus />,
                {
                    id: "",
                    sedeId: sede,
                    postazione: props.postazione,
                    inizio: null,
                    fine: null
                },
                'aggiungiTurno',
                props.index)}
        {props.turni.map((turno, keyTurno) => (
            <div className='turno' key={keyTurno}>{
                getPopup(formatTurno(turno), turno, 'triggerTurno', `${props.index+"turno"}-${keyTurno}`)
            }</div>
        ))}
    </>)
}
