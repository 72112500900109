import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';

function LavoratoreForm({ lavoratore, onSave, onClose }) {
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [orePerGiorno, setOrePerGiorno] = useState({ lunedì: '', martedì: '', mercoledì: '', giovedì: '', venerdì: '', sabato: '' });
    const [totaleOreSettimana, setTotaleOreSettimana] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (lavoratore) {
            setNome(lavoratore.nome);
            setCognome(lavoratore.cognome);
            setOrePerGiorno(lavoratore.mapOrePerGiorno);
            setTotaleOreSettimana(lavoratore.totaleOreSettimana);
        }
    }, [lavoratore]);

    const handleSave = () => {
        const updatedOrePerGiorno = { ...orePerGiorno };
        for (let giorno in updatedOrePerGiorno) {
            const ore = parseInt(updatedOrePerGiorno[giorno], 10);
            if (isNaN(ore) || ore < 0) {
                setErrorMessage(`Ore non valide per '${giorno}'. Inserisci un numero valido.`);
                return;
            }
        }

        const totaleOre = parseInt(totaleOreSettimana, 10);
        if (isNaN(totaleOre) || totaleOre < 0) {
            setErrorMessage("Totale ore settimanali non valido. Inserisci un numero valido.");
            return;
        }

        const newLavoratore = {
            ...lavoratore,
            nome: nome,
            cognome: cognome,
            mapOrePerGiorno: updatedOrePerGiorno,
            totaleOreSettimana: totaleOre
        };
        setErrorMessage('');
        onSave(newLavoratore);
    };

    const handleChangeOrePerGiorno = (giorno, value) => {
        setOrePerGiorno({ ...orePerGiorno, [giorno]: value });
    };

    return (
        <Form>
            {errorMessage && <Alert id="messaggio_popup" variant="danger">{errorMessage}</Alert>}
            <Row>
                <Col xs={12} md={6}>
                    <Form.Group>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control id="nome" type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group>
                        <Form.Label>Cognome</Form.Label>
                        <Form.Control id="cognome" type="text" value={cognome} onChange={(e) => setCognome(e.target.value)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                {['lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'].map((giorno) => (
                    <Col xs={6} sm={4} md={4} key={giorno}>
                        <Form.Group>
                            <Form.Label>{giorno}</Form.Label>
                            <Form.Control
                                id={giorno}
                                type="text"
                                value={orePerGiorno[giorno]}
                                onChange={(e) => handleChangeOrePerGiorno(giorno, e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                ))}
            </Row>
            <Row>
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>Totale ore settimanali</Form.Label>
                        <Form.Control
                            id="totaleOreSettimana"
                            type="text"
                            value={totaleOreSettimana}
                            onChange={(e) => setTotaleOreSettimana(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Button variant="primary" onClick={handleSave} id="salva">Salva</Button>
            <Button variant="secondary" onClick={onClose} id="annulla">Annulla</Button>
        </Form>
    );
}

export default LavoratoreForm;
